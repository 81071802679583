import React from "react";
import { Navigate, useNavigate } from "react-router-dom";

export const LandingPageBody = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className="mx-2 my-4 md:mt-16 flex flex-col md:my-auto items-center bg-cover">
        <p className="text-3xl md:text-4xl lg:text-5xl font-semibold text-center drop-shadow-lg">
          Find a Companion for Your Projects with ProjectCompanion
        </p>
        <p className="mt-8  text-xl text-center md:text-3xl drop-shadow-lg">
          Explore Projects around wide range of tech stack and grow together
        </p>
        <button
          onClick={() => navigate("/home")}
          className="mt-4 md:mt-32 text-xl bg-transparent drop-shadow-lg hover:bg-gray-500 hover:text-white py-2 px-4 border border-black-500 hover:border-transparent rounded-full"
        >
          Browse Projects
        </button>
        <div className="lg:mx-4 mt-8 md:mt-28 grid grid-cols-12">
          <div className="mb-4 md:mb-4 md:mx-4 col-span-12 md:col-span-4">
            <div className="w-1/5 m-auto md:w-full md:m-2">
              <img
                className="h-16"
                src={require("./assets/project-management.png")}
                alt=""
              />
            </div>
            <p className="mt-2 md:mt-4 text-2xl text-center md:text-left font-medium">
              Add Projects
            </p>
            <p className="text-center md:text-left">
              Create Projects in a skill that you want to excel
            </p>
          </div>
          <div className="mb-4 md:mb-4 md:mx-4 col-span-12 md:col-span-4">
            <div className="w-1/5 m-auto md:w-full md:m-2">
              <img className="h-16" src={require("./assets/layers.png")} alt="" />
            </div>
            <p className="mt-2 md:mt-4 text-2xl text-center md:text-left font-medium">
              Find Projects to Collaborate
            </p>
            <p className="text-center md:text-left">Work on projects that you can help people with or Learn a new skill to add to your growing Resume</p>
          </div>
          <div className="mb-4 md:mb-4 md:mx-4 col-span-12 md:col-span-4">
            <div className="w-1/5 m-auto md:w-full md:m-2">
              <img
                className="h-16"
                src={require("./assets/recruitment.png")}
                alt=""
              />
            </div>
            <p className="mt-2 md:mt-4 text-2xl text-center md:text-left font-medium">
              Stand out from the Crowd
            </p>
            <p className="text-center md:text-left ">Grow with your projects to find a New Job or Freelance Opportunity</p>
            <p></p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
