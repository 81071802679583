import React, { useEffect, useState } from "react";
import { UserDashboard } from "../UserDashboard/UserDashboard";
import { FilterProjectList } from "../FilterComponent/FilterComponent";
import { ProjectCard } from "../ProjectCard/ProjectCard";
import { useAuthContext, useUserToken } from "../../../hooks/useAuthContext";
import { useNavigate } from "react-router-dom";
import { SuggestLogin } from "../../SuggestLogin/SuggestLogin";
import { Loading } from "../../Loading/Loading";
import { Transition } from "react-transition-group";

export const LoogedInUserLandingPageBody = () => {
  const { user } = useAuthContext();

  const [listOfProjects, setListOfProjects] = useState([]);
  const [countOfProposals, setCountOfProposals] = useState([]);
  const [loadingAllProjects, setLoadingAllProjects] = useState(true);
  const [loadingCountOfProposals, setLoadingCountOfProposals] = useState(true);
  const [loadingProposalProjectId, setLoadingProposalProjectId] =
    useState(true);
  const [sameUserData, setSameUserData] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpenClose = () => {
    setIsOpen(!isOpen);
  };

  const navigate = useNavigate();

  const getAllTheProjects = async () => {
    try {
      const response = await fetch(`${process.env.API_HOST}/projects`, {
        method: "GET",
        credentials: "include",
      });
      if (!response.ok) {
        setErrorMessage("Error in fetching all projects");
      }
      const data = await response.json();
      setListOfProjects(data);
    } catch (error) {
      console.error("Error fetching projects", error);
      setErrorMessage(error.message);
    } finally {
      setLoadingAllProjects(false);
    }
  };

  const getPorposalsandId = async () => {
    try {
      const response = await fetch(
        `${process.env.API_HOST}/proposalsAndProjectId`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      const data = await response.json();
      if (response.ok) {
        const res = data.filter((item) => {
          return item.proposalowner == user.email;
        });
        setSameUserData(res);
      }
    } catch (error) {
      console.error("Error in fetching proposals and project id: ", error);
    } finally {
      setLoadingProposalProjectId(false);
    }
  };

  const calculateTimeDiff = (date) => {
    const givenDate = new Date(date);

    // Current date
    const currentDate = new Date();

    // Calculate time difference in milliseconds
    const timeDifference = Math.floor((currentDate - givenDate) / 1000);

    if (timeDifference < 60) {
      return `${timeDifference} seconds ago`;
    } else if (timeDifference < 3600) {
      const minutes = Math.floor(timeDifference / 60);
      return `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
    } else if (timeDifference < 86400) {
      const hours = Math.floor(timeDifference / 3600);
      return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
    } else {
      const days = Math.floor(timeDifference / 86400);
      return `${days} ${days === 1 ? "day" : "days"} ago`;
    }
  };

  const getCountOfProposalsOnProject = async () => {
    try {
      const response = await fetch(
        `${process.env.API_HOST}/countOfProposalsOnProject`,
        {
          method: "GET",
          mode: "cors",
          cache: "default",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setCountOfProposals(data);
    } catch (error) {
      console.error("Error fetching count of proposals: ", error);
    } finally {
      setLoadingCountOfProposals(false);
    }
  };

  const getCount = (id) => {
    const res = Object.values(countOfProposals).filter(function (item) {
      return item.projectid == id;
    });
    return res[0]?.count;
  };

  const checkAlreadyApplied = (id) => {
    const res = sameUserData.filter(function (item) {
      return item.projectid == id;
    });
    if (res.length == 1) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    getAllTheProjects();
    getCountOfProposalsOnProject();
    if (user) {
      getPorposalsandId();
    }
  }, []);

  return (
    <React.Fragment>
      {user ? (
        loadingAllProjects ||
        loadingCountOfProposals ||
        loadingProposalProjectId ? (
          <Loading />
        ) : (
          <>
            <div className="xl:my-5 xl:grid xl:grid-cols-12">
              <div className="xl:col-span-3 h-fit">
                <UserDashboard />
                <div>
                  <div className="visible xl:hidden m-4 flex flex-row sm:justify-start justify-between">
                    <div className="m-2 text-xl font-semibold">
                      How to Use...
                    </div>
                    <img
                      src={require("./assets/down-arrow.png")}
                      onClick={toggleOpenClose}
                      className="m-2 h-8"
                    />
                  </div>
                  <Transition in={isOpen} timeout={250} classNames="fade">
                    {(state) =>
                      state === "entered" && (
                        <div
                          className={`m-2 p-2 border border-gray-400 rounded-lg shadow-sm transition-opacity ${
                            state === "entered" ? "opacity-300" : "opacity-0"
                          }`}
                        >
                          <p className="m-1">
                            1. Begin by Creating Your Project
                          </p>
                          <p className="m-1">
                            2. Easily Access Your Projects to Review Proposals
                          </p>
                          <p className="m-1">
                            3. Browse and Choose the Perfect Proposal for Your
                            Project Needs
                          </p>
                          <p className="m-1">4. Accept or Decline Proposals</p>
                          <p className="m-1">
                            5. Connect with Your Ideal Companion to Start
                            Building
                          </p>
                        </div>
                      )
                    }
                  </Transition>
                </div>
              </div>
              <div className="xl:col-span-7 xl:mx-auto mx-2 my-2 p-5 md:border rounded-lg">
                <div className="xl:col-span-2">
                  <FilterProjectList
                    listOfProjects={listOfProjects}
                    setListOfProjects={setListOfProjects}
                  />
                </div>
                {errorMessage && (
                  <>
                    <div>{errorMessage}</div>
                  </>
                )}
                {listOfProjects &&
                  listOfProjects.map((project) => {
                    return (
                      <ProjectCard
                        key={project.id}
                        id={project.id}
                        alreadyApplied={checkAlreadyApplied(project.id)}
                        projectName={project?.projectname}
                        duration={project?.duration}
                        level={project?.projectlevel}
                        noOfProposals={getCount(project.id)}
                        owner={project?.firstname + " " + project?.lastname}
                        description={project?.projectdescription}
                        techStack={project?.techstack}
                        technology={project?.technology}
                        posted={calculateTimeDiff(project?.createdon)}
                      />
                    );
                  })}
              </div>

              <div className="hidden xl:block md:col-span-2 m-2 p-2 h-fit shadow-sm border border-gray rounded-md">
                <h2 className="text-lg font-semibold">How to use...</h2>
                <p className="m-1">1. Begin by Creating Your Project</p>
                <p className="m-1">
                  2. Easily Access Your Projects to Review Proposals
                </p>
                <p className="m-1">
                  3. Browse and Choose the Perfect Proposal for Your Project
                  Needs
                </p>
                <p className="m-1">4. Accept or Decline Proposals</p>
                <p className="m-1">
                  5. Connect with Your Ideal Companion to Start Building
                </p>
              </div>
            </div>
          </>
        )
      ) : (
        <>
          <SuggestLogin />
          <div className="md:w-4/5 md:mx-auto my-4 p-5 items-center md:border rounded-lg">
            <div className="md:col-span-2">
              <FilterProjectList
                listOfProjects={listOfProjects}
                setListOfProjects={setListOfProjects}
              />
            </div>
            {errorMessage && (
              <>
                <div>{errorMessage}</div>
              </>
            )}
            {listOfProjects &&
              listOfProjects.map((project) => {
                return (
                  <ProjectCard
                    key={project.id}
                    id={project.id}
                    projectName={project?.projectname}
                    duration={project?.duration}
                    level={project?.projectlevel}
                    noOfProposals={getCount(project.id)}
                    owner={project?.firstname + " " + project?.lastname}
                    description={project?.projectdescription}
                    techStack={project?.techstack}
                    technology={project?.technology}
                    posted={calculateTimeDiff(project?.createdon)}
                  />
                );
              })}
          </div>
        </>
      )}
    </React.Fragment>
  );
};
