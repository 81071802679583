import React from "react";
import { Header } from "./components/Header/Header";
import { Footer } from "./components/Footer/Footer";
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import { LandingPageBody } from "./components/LandingPageBody/LandingPageBody";
import { LoogedInUserLandingPageBody } from "./components/LoggedInComponents/LandingPage/LandingPage";
import { SendProposal } from "./components/ProposalProject/SendProposals/SendProposals";
import { CreateProject } from "./components/ProposalProject/CreateProject/CreateProject";
import { HandleLogin } from "./components/HandleLogin/HandleLogin";
import { HandleRegister } from "./components/HandleRegister/HandleRegister";
import { CreateProfile } from "./components/UserProfile/CreateProfile";
import { UserProjects } from "./components/ProposalProject/UserProjects/UserProjects";
import { ProjectProposals } from "./components/ProposalProject/ProjectProposals/ProjectProposals";
import { UserProposals } from "./components/ProposalProject/UserProposals/UserProposals";
import { EditProfile } from "./components/UserProfile/EditProfile";
import { OtpVerification } from "./components/HandleRegister/otpVerification";
import { Terms } from "./ASeriousContents/Terms";
import { Privacy } from "./ASeriousContents/Privacy";
import { ProposalProfile } from "./components/ProposalProject/ProjectProposals/Proposalprofile";

// ... (previous imports)

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
};

export const App = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <Layout>
          <LandingPageBody />
        </Layout>
      ),
    },
    {
      path: "login",
      element: (
        <React.Fragment>
          <HandleLogin /> <Footer />
        </React.Fragment>
      ),
    },
    {
      path: "register",
      element: (
        <React.Fragment>
          <HandleRegister />
          <Footer/>
        </React.Fragment>
      ),
    },
    {
      path: "otpVerification",
      element: (
        <React.Fragment>
          <OtpVerification />
        </React.Fragment>
      ),
    },
    {
      path: "home",
      element: (
        <Layout>
          <LoogedInUserLandingPageBody />
        </Layout>
      ),
    },
    {
      path: "user/projects",
      element: (
        <Layout>
          <UserProjects />{" "}
        </Layout>
      ),
    },
    {
      path: "user/proposals",
      element: (
        <Layout>
          <UserProposals />{" "}
        </Layout>
      ),
    },
    {
      path: "user/project/proposals",
      element: (
        <Layout>
          <ProjectProposals />{" "}
        </Layout>
      ),
    },
    {
      path: ":projectId/sendProposal",
      element: (
        <Layout>
          <SendProposal />{" "}
        </Layout>
      ),
    },
    {
      path: "user/createProject",
      element: (
        <Layout>
          <CreateProject />{" "}
        </Layout>
      ),
    },
    {
      path: "user/createProfile",
      element: (
        <Layout>
          <CreateProfile />{" "}
        </Layout>
      ),
    },
    {
      path: "user/editProfile",
      element: (
        <Layout>
          <EditProfile />{" "}
        </Layout>
      ),
    },
    {
      path: "user/senderProfile",
      element: (
        <Layout>
          <ProposalProfile />{" "}
        </Layout>
      ),
    },
    { path: "terms", element: <Terms /> },
    { path: "privacy", element: <Privacy /> },
  ]);

  return (
    <React.Fragment>
      <RouterProvider router={router}>
        <Outlet />
      </RouterProvider>
    </React.Fragment>
  );
};

//export default App;
