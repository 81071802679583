import React from "react";

export const CheckboxFilter = ({
    filterValue,
    filterTitle,
    onChange,
    id
}) => {
    return (
        <React.Fragment>
            <input id={id} type="checkbox" name={filterValue} value={filterValue} onChange={onChange}></input>
            <span className="mx-2">{filterTitle}</span>
        </React.Fragment>
    )
}