import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";
import * as Yup from "yup";

export const CreateProfile = () => {
  const navigate = useNavigate();
  const { user } = useAuthContext();

  const navigateUserSendProposal = () => {
    navigate(-1);
  };

  const handleSubmitNewProfile = async (userFieldValues) => {
    try {
      const response = await fetch(`${process.env.API_HOST}/addProfile`, {
        method: "POST",
        mode: "cors",
        cache: "default",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "same-origin",
        body: JSON.stringify({
          formInputs: userFieldValues,
        }),
      });
  
      const result = await response.json();
    } catch (error) {
      console.error("Error submitting your profile: ", error);
    } finally {
    }
  };

  const handleSubmitProfileProject = async (projectFieldValues) => {
    try {
      const response = await fetch(`${process.env.API_HOST}/addProfileProject`, {
        method: "POST",
        mode: "cors",
        cache: "default",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "same-origin",
        body: JSON.stringify({
          formInputs: projectFieldValues,
        }),
      });
  
      const result = await response.json();
    } catch (error) {
      console.error("Error adding profle projects: ", error);
    } finally {
    }
  };

  useEffect(()=>{
    if(!user){
      navigate('/login');
    }
  })


  const initialValues = {
    firstName: user?.firstName || "Project",
    lastName: user?.lastName || "Companion",
    email: user?.email || "psatyam@gmail.com",
    github: "",
    projects: [
      {
        name: "",
        description: "",
        tech: "",
        link: "",
      },
    ],
    experience: "",
    techFamilier: "",
    newTech: "",
  };

  Yup.addMethod(Yup.string, "validateGitHubUsername", function(errorMessage){
    return this.test('test-github-username', errorMessage, async function(value){
      const {path, createError} = this;

      const response = await fetch(`https://api.github.com/users/${value}`);
      return response?.ok;
    });
  });

  const profileValidationSchema = Yup.object({
    github: Yup.string().validateGitHubUsername('Enter valid GitHub username!!').required("Please enter your GitHub Username"),
    experience: Yup.string().required(
      "Please enter Year's of Experience Ex: 2 Years"
    ),
    techFamilier: Yup.string().required("Please enter the Tech you know"),
    newTech: Yup.string().required(
      "Please enter the Tech you want to learn & grow"
    ),
    projects: Yup.array().of(
      Yup.object({
        name: Yup.string()
          .min(2, "Please enter a Valid Name")
          .required("Please enter a Project Name"),
        tech: Yup.string().required("Please enter the Tech used"),
        description: Yup.string()
          .min(2, "Please enter description of atleast 200 Characters")
          .required("Please enter the description"),
        link: Yup.string().url().required("Please enter Project/GitHub Link"),
      })
    ),
  });

  return (
    <React.Fragment>
      <div className="m-auto flex py-8 items-center justify-center">
        <Formik
          initialValues={initialValues}
          validationSchema={profileValidationSchema}
          onSubmit={(values, { setSubmitting }) => {
            console.log(values);
            //alert(JSON.stringify(values));
            setSubmitting(false);
            handleSubmitNewProfile(values);
            handleSubmitProfileProject(values.projects);
            console.log(values.projects);

            alert("Your Profile is created Successfully");
            navigateUserSendProposal();
          }}
        >
          {({ values }) => (
            <Form>
              <div className="items-center m-2">
                <span className="text-2xl">Personal Information</span>

                <div className="grid grid-cols-6 items-center">
                  <label
                    className="m-2 p-2 col-span-1 text-xl"
                    htmlFor="firstName"
                  >
                    First Name
                  </label>
                  {/* <Field
                    className="m-2 p-2 col-span-2 border rounded-md h-8 border-gray-400"
                    id="firstName"
                    name="firstName"
                    disabled={!values.firstName}
                    value={values.firstName}
                  /> */}
                  <input
                    value={initialValues.firstName}
                    disabled
                    className="m-2 p-2 col-span-2 items-center border rounded-md h-8 border-gray-400"
                  />
                  <label
                    className="m-2 p-2 col-span-1 text-xl"
                    htmlFor="lastName"
                  >
                    Last Name
                  </label>
                  {/* <Field
                    className="m-2 p-2 col-span-2 border rounded-md h-8 border-gray-400"
                    id="lastName"
                    name="lastName"
                  /> */}
                  <input
                    value={initialValues.lastName}
                    disabled
                    className="m-2 p-2 col-span-2 items-center border rounded-md h-8 border-gray-400"
                  />
                </div>
                <div className="grid grid-cols-6 items-center">
                  <label className="m-2 p-2 col-span-1 text-xl" htmlFor="email">
                    Email
                  </label>
                  <input
                    value={initialValues.email}
                    disabled
                    className="m-2 p-2 col-span-2 items-center border rounded-md h-8 border-gray-400"
                  />
                </div>
              </div>

              <hr className="py-2 flex-grow" />

              <div className="items-center m-2">
                <FieldArray name="projects">
                  {({ insert, remove, push }) => (
                    <div>
                      <div className="flex justify-between">
                        <span className="text-2xl">Projects & Experience</span>
                      </div>
                      {values.projects.length > 0 &&
                        values.projects.map((project, index) => (
                          <div className="row" key={index}>
                            <div className="grid grid-cols-6 items-center">
                              <label
                                className="m-2 p-2 md:col-span-1 col-span-2 text-xl"
                                htmlFor={`projects.${index}.name`}
                              >
                                Name
                              </label>
                              <div className="md:col-span-2 col-span-4">
                                <Field
                                  className="ml-2 mr-2 p-2 border rounded-md h-8 border-gray-400"
                                  name={`projects.${index}.name`}
                                  placeholder="Title of project?"
                                  type="text"
                                />
                                <br />
                                <div className="ml-2 text-sm text-red-700">
                                  <ErrorMessage
                                    name={`projects.${index}.name`}
                                  />
                                </div>
                              </div>

                              <label
                                className="m-2 p-2 md:col-span-1 col-span-2 text-xl"
                                htmlFor={`projects.${index}.tech`}
                              >
                                Tech
                              </label>
                              <div className="md:col-span-2 col-span-4">
                                <Field
                                  className="ml-2 mr-2 p-2 border rounded-md h-8 border-gray-400"
                                  name={`projects.${index}.tech`}
                                  placeholder="What tech you used?"
                                  type="text"
                                />
                                <br />
                                <div className="ml-2 text-sm text-red-700">
                                  <ErrorMessage
                                    name={`projects.${index}.tech`}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="md:grid md:grid-cols-6 items-start">
                              <label
                                className="m-2 p-2 md:col-span-1 col-span-2 text-xl"
                                htmlFor={`projects.${index}.description`}
                              >
                                Description
                              </label>
                              <div className="md:col-span-5">
                                <Field
                                  as="textarea"
                                  className="ml-2 mr-2 mt-2 p-4 w-11/12 md:w-full border rounded-md h-24 border-gray-400"
                                  name={`projects.${index}.description`}
                                  placeholder="What did you build?"
                                  type="text"
                                />
                                <br />
                                <div className="ml-2 text-sm text-red-700">
                                  <ErrorMessage
                                    name={`projects.${index}.description`}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="md:grid md:grid-cols-6 items-baseline">
                              <label
                                className="m-2 p-2 col-span-1 text-xl"
                                htmlFor={`projects.${index}.link`}
                              >
                                Project Link
                              </label>
                              <div className="col-span-3">
                                <Field
                                  className="ml-2 mr-2 mt-2 p-4 border w-3/4 rounded-md h-8 border-gray-400"
                                  name={`projects.${index}.link`}
                                  placeholder="Where others can see this project?"
                                  type="text"
                                />
                                <br />
                                <div className="ml-2 text-sm text-red-700">
                                  <ErrorMessage
                                    name={`projects.${index}.link`}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-span-2 mt-2 mb-4 flex justify-start">
                                <button
                                  type="button"
                                  className="mx-2 py-2 px-2 w-max text-red-800 hover:bg-gray-200 hover:text-red-800 rounded-md"
                                  onClick={() => {
                                    if (index !== 0) {
                                      remove(index);
                                    }
                                  }}
                                >
                                  Remove
                                </button>
                              </div>
                          </div>
                        ))}
                        <button
                          type="button"
                          className="mx-2 bg-transparent hover:bg-gray-500 hover:text-white py-2 px-4 border border-black-500 hover:border-transparent rounded-md w-32"
                          onClick={() =>
                            push({
                              name: "",
                              description: "",
                              tech: "",
                              projectlink: "",
                            })
                          }
                        >
                          Add Project
                        </button>
                    </div>
                  )}
                </FieldArray>
              </div>

              <hr className="py-2 flex-grow" />

              <div className="items-center m-2">
                <span className="text-2xl">Links</span>
                <div className="grid grid-cols-6 items-center">
                  <label
                    className="m-2 p-2 md:col-span-1 col-span-2 text-xl"
                    htmlFor="github"
                    placeholder="Enter your GitHub Username"
                  >
                    GitHub
                  </label>
                  <div className="md:col-span-5 col-span-4">
                    <Field
                      className="mr-2 ml-2 mt-2 p-2 md:w-2/4 w-11/12 border rounded-md h-8 border-gray-400"
                      id="github"
                      name="github"
                      placeholder="Enter your GitHub UserName"
                    />
                    <br />
                    <div className="ml-2 text-sm text-red-700">
                      <ErrorMessage name="github" />
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-6 items-center">
                  <label
                    className="m-2 p-2 md:col-span-1 col-span-2 text-xl"
                    htmlFor="experience"
                  >
                    Experience
                  </label>
                  <div className="md:col-span-5 col-span-4">
                    <Field
                      className="ml-2 mr-2 mt-2 p-2 md:w-2/4 w-11/12 border rounded-md h-8 border-gray-400"
                      id="experience"
                      name="experience"
                      placeholder="Do you have experience? In years."
                    />
                    <br />
                    <div className="ml-2 text-sm text-red-700">
                      <ErrorMessage name="experience" />
                    </div>
                  </div>
                </div>
              </div>

              <hr className="py-2 flex-grow" />

              <div className="items-center">
                <span className="text-2xl">Tech Strong & Weak</span>

                <div className="grid grid-cols-6 items-center">
                  <label
                    className="m-2 p-2 md:col-span-1 col-span-2 text-xl"
                    htmlFor="techFamilier"
                  >
                    Strong
                  </label>
                  <div className="md:col-span-5 col-span-4">
                    <Field
                      className="ml-2 mr-2 mt-2 p-2 md:w-3/4 w-11/12 border rounded-md h-8 border-gray-400"
                      id="techFamilier"
                      name="techFamilier"
                      placeholder="Tech you are familier with"
                    />
                    <br />
                    <div className="ml-2 text-sm text-red-700">
                      <ErrorMessage name="techFamilier" />
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-6 items-center">
                  <label
                    className="m-2 p-2 md:col-span-1 col-span-2 text-xl"
                    htmlFor="newTech"
                  >
                    Weak
                  </label>
                  <div className="md:col-span-5 col-span-4">
                    <Field
                      className="ml-2 mr-2 mt-2 p-2 md:w-3/4 w-11/12 border rounded-md h-8 border-gray-400"
                      id="newTech"
                      name="newTech"
                      placeholder="Tech you want to learn"
                    />
                    <br />
                    <div className="ml-2 text-sm text-red-700">
                      <ErrorMessage name="newTech" />
                    </div>
                  </div>
                </div>
              </div>

              <button
                className="mx-2 bg-transparent hover:bg-gray-500  py-2 px-4 border border-black-500 hover:border-transparent rounded-full w-32"
                type="submit"
              >
                Submit
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
};
