import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../Loading/Loading";

export const UserProposals = () => {
  const navigate = useNavigate();
  const { user } = useAuthContext();

  const [listofProposals, setListOfProposals] = useState([]);
  const [loadingAllProposals, setLoadingAllProposals] = useState(true);

  //   Implement after JWT is done
  const getAllUserProposals = async () => {
    try {
      const response = await fetch(
        `${process.env.API_HOST}/listUserProposals/`,
        {
          method: "GET",
          mode: "cors",
          cache: "default",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          redirect: "follow",
          referrerPolicy: "same-origin",
        }
      );
      const data = await response.json();
      setListOfProposals(data);
    } catch (error) {
      console.error("Error fetching all user proposals: ", error);
    } finally {
      setLoadingAllProposals(false);
    }
  };

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
    getAllUserProposals();
  }, []);

  return (
    <React.Fragment>
      {loadingAllProposals ? (
        <Loading />
      ) : (
        <>
          <div className="text-3xl m-4">Your Proposals</div>
          {listofProposals.map((proposal, index) => {
            return (
              <>
                <div
                  key={proposal.id}
                  className="m-auto h-fit border mx-8 my-2 rounded-md border-gray-500"
                >
                  <div className="text-xl m-2">Proposal Approach</div>
                  <div className="m-2">{proposal.proposedapproach}</div>
                  <div className="flex">
                    <div className="m-2 font-medium">Proposed Duration: </div>
                    <div className="m-2">{proposal.proposedduration}</div>
                  </div>
                  <div className="flex">
                    <div className="m-2 font-medium">Proposed Tools: </div>
                    <div className="m-2">{proposal.proposedtools}</div>
                  </div>
                </div>
              </>
            );
          })}{" "}
        </>
      )}
    </React.Fragment>
  );
};
