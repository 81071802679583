import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { Loading } from "../../Loading/Loading";

export const ProjectProposals = () => {
  const { user } = useAuthContext();
  const navigate = useNavigate();

  const location = useLocation();

  const [listOfProposals, setListOfProposals] = useState([]);
  const [loadingAllProposals, setLoadingAllProposals] = useState(true);

  const navigateSenderProfile = (email) => {
    navigate("/user/senderProfile", {
      state: {
        emailAddress: email,
      },
    });
  };

  const getAllProposals = async () => {
    try {
      const response = await fetch(
        `${process.env.API_HOST}/listProposals/${location.state.id}`,
        {
          method: "GET",
          mode: "cors",
          credentials: "include",
        }
      );
      const data = await response.json();
      setListOfProposals(data);
    } catch (error) {
      console.error("Error fetching all proposals for this project: ", error);
    } finally {
      setLoadingAllProposals(false);
    }
  };

  const updateProposalStatus = async (id, status, email, projectid) => {
    const response = await fetch(
      `${process.env.API_HOST}/updateProposalStatus/${id}`,
      {
        method: "POST",
        mode: "cors",
        cache: "default",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        body: JSON.stringify({
          status: `${status}`,
          project: location.state.projectName,
          toEmail: email,
          projectId: projectid,
        }),
        referrerPolicy: "same-origin",
      }
    );
    const result = await response.json();
    if (response.ok) {
      alert("Notification has been sent to the user");
      navigate("/user/projects");
    } else {
      if (result.message) {
        alert(result.message);
      }
    }
  };

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
    getAllProposals();
  }, [user]);

  return (
    <React.Fragment>
      {loadingAllProposals ? (
        <Loading />
      ) : (
        <>
          <div className="text-3xl m-4">
            Proposals for {location.state.projectName}
          </div>
          {listOfProposals.map((proposal, index) => {
            return (
              <div
                key={proposal}
                className="m-auto h-fit border mx-8 my-2 rounded-md border-gray-500"
              >
                <div className="text-xl m-2">Proposal Approach</div>
                <div className="m-2">{proposal.proposedapproach}</div>
                <div className="flex">
                  <div className="m-2 font-medium">Proposed Duration: </div>
                  <div className="m-2">{proposal.proposedduration}</div>
                </div>
                <div className="flex">
                  <div className="m-2 font-medium">Proposed Tools: </div>
                  <div className="m-2">{proposal.proposedtools}</div>
                </div>
                <div className="flex">
                  <button
                    onClick={() => {
                      navigateSenderProfile(proposal.proposalowner);
                    }}
                    className="m-2 font-medium hover:text-blue-800"
                  >
                    Sender's Profile
                  </button>
                </div>
                {proposal.status == "Accepted" ||
                proposal.status == "Rejected" ? (
                  <>
                    <div className="text-xl m-4">{proposal.status}</div>
                  </>
                ) : (
                  <>
                    <div className="">
                      <button
                        onClick={() => {
                          updateProposalStatus(
                            proposal.id,
                            "Accepted",
                            proposal.proposalowner,
                            proposal.projectid
                          );
                        }}
                        className="mx-2 my-2 bg-transparent hover:bg-gray-500 hover:text-white py-2 px-4 border border-black-500 hover:border-transparent rounded-full w-fit"
                        type="button"
                      >
                        Accept
                      </button>
                      <button
                        onClick={() => {
                          updateProposalStatus(
                            proposal.id,
                            "Rejected",
                            proposal.proposalowner,
                            proposal.projectid
                          );
                        }}
                        className="mx-2 my-2 bg-transparent hover:bg-gray-500 hover:text-white py-2 px-4 border border-black-500 hover:border-transparent rounded-full w-fit"
                        type="button"
                      >
                        Reject
                      </button>
                    </div>
                  </>
                )}
              </div>
            );
          })}
        </>
      )}
    </React.Fragment>
  );
};
