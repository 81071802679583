import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../hooks/useAuthContext";

export const ProjectCard = (props) => {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const name = user?.firstName + " " + user?.lastName;
  const flag = name == props.owner;


  return (
    <React.Fragment>
      <div className="my-8 rounded-lg border h-fit">
        <div className="flex justify-between">
          <div className="m-2 text-2xl">{props.projectName}</div>
          {props.alreadyApplied ? (
            <>
              <div className="flex">
                <img
                  className="mx-2 my-2 w-8 h-8 p-1"
                  src={require("./assets/applied.png")}
                  alt="Bordered avatar"
                />
                <div className="m-2 text-xl">Applied</div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>

        <div className="flex text-sm">
          <div className="p-2">Duration: {props.duration}</div>
          <div className="p-2">Level: {props.level}</div>
          <div className="p-2">Posted {props.posted}</div>
        </div>
        <div className="pl-2 pb-2 pr-2 text-justify">{props.description}</div>
        <div className="p-2 text-sm">
          Proposals:{" "}
          {props.noOfProposals == null
            ? "No Proposals yet"
            : props.noOfProposals}
        </div>
        <div className="flex justify-between">
          <div className="p-2">Created by: {props.owner}</div>
          {
            <div className="">
              <button
                disabled={props.alreadyApplied}
                onClick={() => {
                  user
                    ? flag
                      ? alert("You cannot send Proposals to your Own Projects")
                      : navigate(`/${props.id}/sendProposal`)
                    : navigate("/login");
                }}
                className="disabled:cursor-not-allowed mx-2 my-2 bg-transparent hover:bg-gray-500 hover:text-white py-2 px-4 border border-black-500 hover:border-transparent rounded-full"
              >
                Send Proposal
              </button>
            </div>
          }
        </div>
      </div>
    </React.Fragment>
  );
};
