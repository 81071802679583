import React from "react";
import { Link } from "react-router-dom";

export const Header = () => {
    return (
        <React.Fragment>
            <div className="h-18 w-1/2 mx-auto pt-10">
                <Link className="text-2xl font-bold" to="/">ProjectCompanion</Link>
            </div>
        </React.Fragment>
    )
}