import React, { useEffect, useState } from "react";
import { Formik, Field, Form, formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useNavigate } from "react-router-dom";

export const CreateProject = () => {
  const navigate = useNavigate();

  const { user } = useAuthContext();

  const handleSubmitNewProject = async (projectFieldValues) => {
    try {
      const response = await fetch(`${process.env.API_HOST}/addProject`, {
        method: "POST",
        mode: "cors",
        cache: "default",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "same-origin",
        body: JSON.stringify({
          formInputs: projectFieldValues,
        }),
      });

      const result = await response.json();

      if (!response.ok) {
        alert("Unable to add project please check input and try again!");
      } else if (response.ok) {
        alert("Project added successfully. It's time to work!!");
        navigate("/home");
      }
    } catch (error) {
      console.error("Error adding your project: ", error);
    } finally {
    }
  };

  const projectValidationSchema = Yup.object({
    title: Yup.string().min(4).required("Please enter a Title for Project"),
    description: Yup.string()
      .min(200, "Description should be atleast 200 Characters")
      .required("Description is Requried"),
    duration: Yup.string().min(2).required("Duration is required in Months"),
    techStack: Yup.string()
      .min(2)
      .required("Please enter Tech Stack for Project"),
  });

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  });

  return (
    <React.Fragment>
      <div className="mx-2 md:m-auto md:w-4/5">
        <Formik
          initialValues={{
            title: "",
            description: "",
            duration: "",
            level: "",
            technology: "frontend",
            techStack: "",
          }}
          validationSchema={projectValidationSchema}
          onSubmit={(projectFieldValues, { setSubmitting }) => {
            // alert(JSON.stringify(projectFieldValues));
            // return;
            setSubmitting(false);
            handleSubmitNewProject(projectFieldValues);
            console.log(values.projects);
          }}
        >
          <div className="m-2 md:m-4">
            <span className="text-2xl font-medium">Add a Project</span>
            <hr className="my-3" />
            <Form className="w-full mx-2 md:mx-10">
              <div className="md:grid md:grid-cols-12">
                <label
                  className="md:m-2 md:p-2 md:col-span-2 text-xl"
                  htmlFor="title"
                >
                  Title
                </label>
                <div className="md:col-span-9">
                  <Field
                    className="md:m-2 p-2 my-2 md:w-4/5 border rounded-md h-8 border-gray-400"
                    id="title"
                    name="title"
                    placeholder="Name of Project"
                  />
                  <br />
                  <div className="md:mx-4 text-sm text-red-700">
                    <ErrorMessage className="" name="title" />
                  </div>
                </div>
              </div>
              <div className="md:grid md:grid-cols-12">
                <label
                  className="md:col-span-2 md:m-2 md:p-2 text-xl"
                  htmlFor="Description"
                >
                  Description
                </label>
                <div className="md:col-span-9">
                  <Field
                    as="textarea"
                    className="w-3/5 md:w-4/5 h-32 md:m-2 p-2 my-2 border rounded-md border-gray-400"
                    id="description"
                    name="description"
                  />
                  <br />
                  <div className="md:mx-4 text-sm text-red-700">
                    <ErrorMessage name="description" />
                  </div>
                </div>
              </div>
              <div className="md:grid md:grid-cols-12">
                <label
                  className="md:col-span-2 md:m-2 md:p-2 text-xl"
                  htmlFor="Duration"
                >
                  Duration
                </label>
                <div className="md:col-span-9">
                  <Field
                    className="h-8 md:m-2 p-2 my-2 md:w-1/4 w-2/4 border rounded-md border-gray-400"
                    id="duration"
                    name="duration"
                    placeholder="In months ex: 3 Months"
                  />
                  <br />
                  <div className="md:mx-4 text-sm text-red-700">
                    <ErrorMessage name="duration" />
                  </div>
                </div>
              </div>
              <div className="md:grid md:grid-cols-12">
                <label
                  className="text-xl md:col-span-2 md:m-2 md:p-2"
                  htmlFor="level"
                >
                  Level
                </label>
                <Field
                  as="select"
                  className="px-1 m-2 h-8 md:col-span-9 w-1/12 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600"
                  name="level"
                >
                  <option>I</option>
                  <option>II</option>
                  <option>III</option>
                </Field>
              </div>
              <div className="md:grid md:grid-cols-12">
                <label
                  className="text-xl md:col-span-2 md:m-2 md:p-2"
                  htmlFor="technology"
                >
                  Technology
                </label>
                <Field
                  as="select"
                  className="p-2 m-2 md:col-span-9 w-2/5 md:w-1/5 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600"
                  id="technology"
                  name="technology"
                >
                  <optgroup label="Web">
                    <option value="frontend">Frontend</option>
                    <option value="backend">Backend</option>
                    <option value="fullstack">Fullstack</option>
                  </optgroup>
                  <optgroup label="Mobile App">
                    <option value="android">Android</option>
                    <option value="ios">iOS</option>
                  </optgroup>
                  <optgroup label="Data Science & Analysis">
                    <option value="da">Data Analysis</option>
                    <option value="mlai">ML/AI</option>
                  </optgroup>
                  <optgroup label="Cloud & Automation">
                    <option value="cloud">Cloud</option>
                    <option value="iot">IoT</option>
                  </optgroup>
                </Field>
              </div>
              <div className="md:grid md:grid-cols-12">
                <label
                  className="md:m-2 md:p-2 col-span-2 text-xl"
                  htmlFor="techStack"
                >
                  Tools
                </label>
                <div className="md:col-span-9">
                  <Field
                    className="md:m-2 p-2 my-2 w-4/5 border rounded-md h-8 border-gray-400"
                    id="techStack"
                    name="techStack"
                    placeholder="Mention tools you will be using when building project"
                  />
                  <br />
                  <div className="md:mx-4 text-sm text-red-700">
                    <ErrorMessage name="techStack" />
                  </div>
                </div>
              </div>
              <div className="my-4 md:mx-2">
                <button
                  type="submit"
                  className="bg-transparent hover:bg-gray-500 hover:text-white py-2 px-4 border border-black-500 hover:border-transparent rounded-full"
                >
                  Create Project
                </button>
              </div>
            </Form>
          </div>
        </Formik>
      </div>
    </React.Fragment>
  );
};
