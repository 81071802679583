import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { Loading } from "../../Loading/Loading";

export const UserProjects = () => {
  const navigate = useNavigate();
  const { user } = useAuthContext();

  const navigateProjectProposals = (projectId, name) => {
    navigate("/user/project/proposals", {
      state: {
        id: projectId,
        projectName: name,
      },
    });
  };

  const [listOfProjects, setListOfProjects] = useState([]);
  const [countOfProposals, setCountOfProposals] = useState([]);

  const [loadingAllProjects, setLoadingAllProjects] = useState(true);
  const [loadingCountOfProposals, setLoadingCountOfProposals] = useState(true);

  const getAllUserProjects = async () => {
    try {
      const response = await fetch(`${process.env.API_HOST}/userProjects`, {
        method: "GET",
        mode: "cors",
        cache: "default",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      setListOfProjects(data);
    } catch (error) {
      console.error("Error fetching all projects: ", error);
    } finally {
      setLoadingAllProjects(false);
    }
  };

  const getCountOfProposalsOnProject = async () => {
    try {
      const response = await fetch(
        `${process.env.API_HOST}/countOfProposalsOnProject`,
        {
          method: "GET",
          mode: "cors",
          cache: "default",
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setCountOfProposals(data);
      //console.log(data);
    } catch (error) {
      console.error("Error fetching count of proposals for projects: ", error);
    } finally {
      setLoadingCountOfProposals(false);
    }
  };

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
    getAllUserProjects();
    getCountOfProposalsOnProject();
  }, []);

  const getCount = (id) => {
    const res = Object.values(countOfProposals).filter(function (item) {
      return item.projectid == id;
    });
    console.log(res);
    return res[0]?.count;
  };

  const calculateTimeDiff = (date) => {
    const givenDate = new Date(date);

    // Current date
    const currentDate = new Date();

    // Calculate time difference in milliseconds
    const timeDifference = Math.floor((currentDate - givenDate) / 1000);

    if (timeDifference < 60) {
      return `${timeDifference} seconds ago`;
    } else if (timeDifference < 3600) {
      const minutes = Math.floor(timeDifference / 60);
      return `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
    } else if (timeDifference < 86400) {
      const hours = Math.floor(timeDifference / 3600);
      return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
    } else {
      const days = Math.floor(timeDifference / 86400);
      return `${days} ${days === 1 ? "day" : "days"} ago`;
    }
  };

  return (
    <React.Fragment>
      {loadingAllProjects || loadingCountOfProposals ? (
        <Loading />
      ) : (
        <>
          <div className="text-3xl m-4">Your Projects</div>

          {listOfProjects.map((project) => {
            return (
              <>
                <div
                  key={project.id}
                  className="h-fit border mx-8 my-2 rounded-md border-gray-500"
                >
                  <div className="mt-2 ml-2 mr-2 mb-4 text-xl">
                    {project.projectname}
                  </div>
                  <div className="flex text-sm">
                    <div className="p-2">{project.duration}</div>
                    <div className="p-2">{project.level}</div>
                    <div className="p-2">
                      Posted {calculateTimeDiff(project.createdon)}
                    </div>
                  </div>
                  <div className="pl-2 pb-2 pr-2">
                    {project.projectdescription}
                  </div>
                  <div className="p-2 text-sm">
                    Proposals: {getCount(project.id)}
                  </div>
                  <div className="flex justify-between">
                    <div className="p-2">
                      Created by: {user?.firstName + " " + user?.lastName}
                    </div>
                    <div className="">
                      <button
                        onClick={() => {
                          navigateProjectProposals(
                            project.id,
                            project.projectname
                          );
                        }}
                        className="mx-2 my-2 bg-transparent hover:bg-gray-500 hover:text-white py-2 px-4 border border-black-500 hover:border-transparent rounded-full w-fit"
                        type="button"
                      >
                        See all Proposals
                      </button>
                    </div>
                  </div>
                  {project.status?.includes("Accepted") && (
                    <>
                      <div className="ml-2 mr-2 mb-4 text-lg">
                        You have chosen the companion for this project. Please
                        connect with your companion at{" "}
                        {project.status.replace("Accepted_for_", "")}
                      </div>
                    </>
                  )}
                </div>
              </>
            );
          })}
        </>
      )}
    </React.Fragment>
  );
};
