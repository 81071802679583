import React from "react";
import { useNavigate } from "react-router-dom";

export const SuggestLogin = () => {

    const navigate = useNavigate();

    return (
        <React.Fragment>
            <div className="flex items-center h-16 cursor-pointer p-4 bg-gray-200 rounded-xl">
                <span className="lg:w-2/5 mx-auto text-center text-2xl" onClick={() => {
                    navigate("/login");
                }}>Login & Start Building Together!!</span>
            </div>
        </React.Fragment>
    );
};