import React from "react";
import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../hooks/useAuthContext";

export const UserDashboard = () => {
  const navigate = useNavigate();

  const { user } = useAuthContext();

  const [numberOfProjects, setNumberOfProjects] = useState(0);
  const [numberOfProposals, setNumberOfProposals] = useState(0);

  const getNoOfProjects = async () => {
    const response = await fetch(`${process.env.API_HOST}/getCountOfProjects`, {
      method: "GET",
      credentials: "include",
    });
    const data = await response.json();
    setNumberOfProjects(data);
  };

  const getNoOfProposals = async () => {
    const response = await fetch(
      `${process.env.API_HOST}/getCountOfProposals`,
      {
        method: "GET",
        credentials: "include",
      }
    );
    const data = await response.json();
    setNumberOfProposals(data);
  };

  useEffect(() => {
    getNoOfProjects();
    getNoOfProposals();
  }, []);
  return (
    <React.Fragment>
      <div className="border mx-5 my-2 shadow-sm rounded-lg border-gray">
                <h3 className="m-2 text-xl font-bold">DASHBOARD</h3>
                <p className="mt-1 ml-1 p-2 text-lg">
                  {" "}
                  <span className="font-semibold">NAME: </span>
                  {user.firstName.toUpperCase() +
                    " " +
                    user.lastName.toUpperCase()}
                </p>
                <p className="ml-1  p-2 text-lg">
                  {" "}
                  <span className="font-semibold">PROJECTS: </span>{(numberOfProjects!=0) ? (numberOfProjects[0].count) : 0} Created
                </p>
                <p className="ml-1 p-2 text-lg">
                  {" "}
                  <span className="font-semibold">PROPOSALS: </span>{(numberOfProposals!=0) ? (numberOfProposals[0].count) : 0} Submitted
                </p>
                <p className="ml-1 p-2 text-lg">
                  {" "}
                  <span className="font-semibold">WORK IN PROGRESS: </span>0
                </p>
                <p className="ml-1 p-2 text-lg">
                  {" "}
                  <span className="font-semibold">COMPELETED: </span>0{" "}
                </p>

                <div className="grid grid-cols-2">
                  <button
                    onClick={() => {
                      navigate("/user/projects");
                    }}
                    className="mx-4 my-2 shadow-sm text-lg bg-transparent hover:bg-gray-500 hover:text-white py-2 px-4 border border-black-500 hover:border-transparent rounded-lg"
                  >
                    Your Projects
                  </button>
                  <button
                    onClick={() => {
                      navigate("/user/proposals");
                    }}
                    className="mx-4 my-2 shadow-sm text-lg bg-transparent hover:bg-gray-500 hover:text-white py-2 px-4 border border-black-500 hover:border-transparent rounded-lg"
                  >
                    Your Proposals
                  </button>
                  <button
                    onClick={() => {
                      navigate("/user/createProject");
                    }}
                    className="mx-4 my-2 shadow-sm text-lg bg-transparent hover:bg-gray-500 hover:text-white py-2 px-2 border border-black-500 hover:border-transparent rounded-lg"
                  >
                    Add Project
                  </button>
                  <button
                    onClick={() => {
                      navigate("/user/editProfile");
                    }}
                    className="mx-4 my-2 shadow-sm text-lg bg-transparent hover:bg-gray-500 hover:text-white py-2 px-2 border border-black-500 hover:border-transparent rounded-lg"
                  >
                    Edit Profile
                  </button>
                </div>
              </div>
    </React.Fragment>
  );
};
