import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Navigate } from "react-router-dom";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { useAuthContext } from "../../hooks/useAuthContext";
import { Loading } from "../Loading/Loading";

export const EditProfile = () => {
  const { user } = useAuthContext();

  const [userProfile, setUserProfile] = useState([]);
  const [listOfProfileProjects, setListOfProfileProjects] = useState([]);
  const [projectRemoved, setProjectRemoved] = useState([]);
  const [redirectUserToLandingPage, setRedirectUserToLandingPage] =
    useState(false);

  const [loadingUserProfile, setLoadingUserProfile] = useState(true);
  const [loadingProfileProjects, setLoadingProfileProjects] = useState(true);

  const getUserProfile = async () => {
    try {
      const response = await fetch(`${process.env.API_HOST}/getUserProfile`, {
        method: "GET",
        credentials: "include",
      });
      const data = await response.json();
      setUserProfile(data);
    } catch (error) {
      console.error("Error fetching user profile:; ", error);
    } finally {
      setLoadingUserProfile(false);
    }
  };

  const getProfileProjects = async () => {
    try {
      const response = await fetch(
        `${process.env.API_HOST}/getProfileProjects`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      const data = await response.json();
      setListOfProfileProjects(data);
    } catch (error) {
      console.error("Error fetching profile projects: ", error);
    } finally {
      setLoadingProfileProjects(false);
    }
  };

  useEffect(() => {
    if (!user) {
      Navigate("/login");
    }
    getProfileProjects();
    getUserProfile();
  }, []);

  const initialValues = {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    projects: listOfProfileProjects,
    github: String(userProfile.map((index) => index.github)),
    experience: String(userProfile.map((index) => index.experience)),
    techFamilier: String(userProfile.map((index) => index.oldtech)),
    newTech: String(userProfile.map((index) => index.newtech)),
  };

  Yup.addMethod(Yup.string, "validateGitHubUsername", function (errorMessage) {
    return this.test(
      "test-github-username",
      errorMessage,
      async function (value) {
        const { path, createError } = this;

        const response = await fetch(`https://api.github.com/users/${value}`);
        return response?.ok;
      }
    );
  });

  const profileValidationSchema = Yup.object({
    github: Yup.string()
      .validateGitHubUsername("Enter valid GitHub username!!")
      .required("Please enter your GitHub Username"),
    experience: Yup.string().required(
      "Please enter Year's of Experience Ex: 2 Years"
    ),
    techFamilier: Yup.string().required("Please enter the Tech you know"),
    newTech: Yup.string().required(
      "Please enter the Tech you want to learn & grow"
    ),
    projects: Yup.array().of(
      Yup.object({
        name: Yup.string()
          .min(2, "Please enter a Valid Name")
          .required("Please enter a Project Name"),
        tech: Yup.string().required("Please enter the Tech used"),
        description: Yup.string()
          .min(2, "Please enter description of atleast 200 Characters")
          .required("Please enter the description"),
        projectlink: Yup.string()
          .url("Please enter valid url!!")
          .required("Please enter Project/GitHub Link"),
      })
    ),
  });

  const handleEditProfile = async (userFieldValues) => {
    try {
      const response = await fetch(`${process.env.API_HOST}/editProfile`, {
        method: "POST",
        mode: "cors",
        cache: "default",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "same-origin",
        body: JSON.stringify({
          formInputs: userFieldValues,
        }),
      });

      const result = await response.json();
    } catch (error) {
      console.error("Error editing profile: ", error);
    } finally {
    }
  };

  const handleEditProfileProjects = async (userFieldValues) => {
    try {
      const response = await fetch(
        `${process.env.API_HOST}/editProfileProjects`,
        {
          method: "POST",
          mode: "cors",
          cache: "default",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          redirect: "follow",
          referrerPolicy: "same-origin",
          body: JSON.stringify({
            formInputs: userFieldValues,
            projectRemoved: projectRemoved,
          }),
        }
      );

      const result = await response.json();
    } catch (error) {
      console.error("Error editing profile projects: ", error);
    } finally {
    }
  };

  return (
    <React.Fragment>
      {loadingUserProfile && loadingProfileProjects ? (
        <Loading />
      ) : (
        <div className="m-auto flex py-8 items-center justify-center">
          <Formik
            initialValues={initialValues}
            validationSchema={profileValidationSchema}
            enableReinitialize="true"
            onSubmit={(values) => {
              handleEditProfile(values);
              handleEditProfileProjects(values);
              alert("Profile Updated Successfully");
              setRedirectUserToLandingPage(true);
            }}
          >
            {({ values }) => (
              <Form>
                <div className="items-center m-2">
                  <span className="text-2xl">Personal Information</span>

                  <div className="grid grid-cols-6 items-center">
                    <label
                      className="m-2 p-2 col-span-1 text-xl"
                      htmlFor="firstName"
                    >
                      First Name
                    </label>
                    {/* <Field
                  className="m-2 p-2 col-span-2 border rounded-md h-8 border-gray-400"
                  id="firstName"
                  name="firstName"
                  disabled={!values.firstName}
                  value={values.firstName}
                /> */}
                    <input
                      value={initialValues.firstName}
                      disabled
                      className="m-2 p-2 col-span-2 items-center border rounded-md h-8 border-gray-400"
                    />
                    <label
                      className="m-2 p-2 col-span-1 text-xl"
                      htmlFor="lastName"
                    >
                      Last Name
                    </label>
                    {/* <Field
                  className="m-2 p-2 col-span-2 border rounded-md h-8 border-gray-400"
                  id="lastName"
                  name="lastName"
                /> */}
                    <input
                      value={initialValues.lastName}
                      disabled
                      className="m-2 p-2 col-span-2 items-center border rounded-md h-8 border-gray-400"
                    />
                  </div>
                  <div className="grid grid-cols-6 items-center">
                    <label
                      className="m-2 p-2 col-span-1 text-xl"
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <input
                      value={initialValues.email}
                      disabled
                      className="m-2 p-2 col-span-2 items-center border rounded-md h-8 border-gray-400"
                    />
                  </div>
                </div>

                <hr className="py-2 flex-grow" />

                <div className="items-center m-2">
                  <span className="text-2xl">Links</span>
                  <div className="grid grid-cols-6 items-center">
                    <label
                      className="m-2 p-2 md:col-span-1 col-span-2 text-xl"
                      htmlFor="github"
                      placeholder="Enter your GitHub Username"
                    >
                      GitHub
                    </label>
                    <div className="md:col-span-5 col-span-4">
                      <Field
                        className="mr-2 ml-2 mt-2 p-2 md:w-2/4 border rounded-md h-8 border-gray-400"
                        id="github"
                        name="github"
                        placeholder="What is your Github?"
                      />
                      <br />
                      <div className="ml-2 text-sm text-red-700">
                        <ErrorMessage name="github" />
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-6 items-center">
                    <label
                      className="m-2 p-2 md:col-span-1 col-span-2 text-xl"
                      htmlFor="experience"
                    >
                      Experience
                    </label>
                    <div className="md:col-span-5 col-span-4">
                      <Field
                        className="ml-2 mr-2 mt-2 p-2 md:w-2/4 border rounded-md h-8 border-gray-400"
                        id="experience"
                        name="experience"
                        placeholder="Do you have experience? In years."
                      />
                      <br />
                      <div className="ml-2 text-sm text-red-700">
                        <ErrorMessage name="experience" />
                      </div>
                    </div>
                  </div>
                </div>

                <hr className="py-2 flex-grow" />
                <div className="items-center m-2">
                <span className="text-2xl">Projects</span>
                  <FieldArray name="projects">
                    {({ insert, remove, push }) => (
                      <div>
                        {values.projects.length > 0 &&
                          values.projects.map((project, index) => (
                            <div className="row" key={index}>
                              <div className="grid grid-cols-6 items-center">
                                <label
                                  className="m-2 p-2 md:col-span-1 col-span-2 text-xl"
                                  htmlFor={`projects.${index}.name`}
                                >
                                  Name
                                </label>
                                <div className="md:col-span-2 col-span-4">
                                  <Field
                                    className="ml-2 mr-2 p-2 border rounded-md h-8 border-gray-400"
                                    name={`projects.${index}.name`}
                                    placeholder="Title of project?"
                                    type="text"
                                  />
                                  <br />
                                  <div className="ml-2 text-sm text-red-700">
                                    <ErrorMessage
                                      name={`projects.${index}.name`}
                                    />
                                  </div>
                                </div>

                                <label
                                  className="md:ml-16 m-2 p-2 md:col-span-1 col-span-2 text-xl"
                                  htmlFor={`projects.${index}.tech`}
                                >
                                  Tech
                                </label>
                                <div className="md:col-span-2 col-span-4">
                                  <Field
                                    className="ml-2 mr-2 p-2 border rounded-md h-8 border-gray-400"
                                    name={`projects.${index}.tech`}
                                    placeholder="What tech you used?"
                                    type="text"
                                  />
                                  <br />
                                  <div className="ml-2 text-sm text-red-700">
                                    <ErrorMessage
                                      name={`projects.${index}.tech`}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="md:grid md:grid-cols-6 items-start">
                                <label
                                  className="m-2 p-2 md:col-span-1 text-xl"
                                  htmlFor={`projects.${index}.description`}
                                >
                                  Description
                                </label>
                                <div className="md:col-span-5">
                                  <Field
                                    as="textarea"
                                    className="ml-2 mr-2 mt-2 p-4 w-11/12 md:w-full border rounded-md h-24 border-gray-400"
                                    name={`projects.${index}.description`}
                                    placeholder="What did you build?"
                                    type="text"
                                  />
                                  <br />
                                  <div className="ml-2 text-sm text-red-700">
                                    <ErrorMessage
                                      name={`projects.${index}.description`}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="md:grid md:grid-cols-6 items-baseline">
                                <label
                                  className="m-2 p-2 md:col-span-1 text-xl"
                                  htmlFor={`projects.${index}.projectlink`}
                                >
                                  Project Link
                                </label>
                                <div className="md:col-span-3">
                                  <Field
                                    className="ml-2 mr-2 mt-2 p-4 w-11/12 md:w-full border rounded-md h-8 border-gray-400"
                                    name={`projects.${index}.projectlink`}
                                    placeholder="Where others can see this project?"
                                    type="text"
                                  />
                                  <br />
                                  <div className="ml-2 text-sm text-red-700">
                                    <ErrorMessage
                                      name={`projects.${index}.projectlink`}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-span-2 mt-2 mb-4 flex justify-start">
                                <button
                                  type="button"
                                  className="mx-2 py-2 px-2 w-max text-red-800 hover:bg-gray-200 hover:text-red-800 rounded-md"
                                  onClick={() => {
                                    if (index !== 0) {
                                      console.log(project.id);
                                      setProjectRemoved({
                                        projectRemoved: [project.id],
                                      });
                                      remove(index);
                                    }
                                  }}
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          ))}
                        <button
                          type="button"
                          className="mx-2 bg-transparent hover:bg-gray-500 hover:text-white py-2 px-4 border border-black-500 hover:border-transparent rounded-md w-32"
                          onClick={() =>
                            push({
                              name: "",
                              description: "",
                              tech: "",
                              projectlink: "",
                            })
                          }
                        >
                          Add Project
                        </button>
                      </div>
                    )}
                  </FieldArray>
                </div>

                <hr className="py-2 flex-grow" />

                <div className="items-center">
                  <span className="text-2xl">Tech Strong & Weak</span>

                  <div className="grid grid-cols-6 items-center">
                    <label
                      className="m-2 p-2 md:col-span-1 col-span-2 text-xl"
                      htmlFor="techFamilier"
                    >
                      Strong
                    </label>
                    <div className="md:col-span-5 col-span-4">
                      <Field
                        className="ml-2 mr-2 mt-2 p-2 md:w-3/4 border rounded-md h-8 border-gray-400"
                        id="techFamilier"
                        name="techFamilier"
                        placeholder="Tech you are familier with"
                      />
                      <br />
                      <div className="ml-2 text-sm text-red-700">
                        <ErrorMessage name="techFamilier" />
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-6 items-center">
                    <label
                      className="m-2 p-2 md:col-span-1 col-span-2 text-xl"
                      htmlFor="newTech"
                    >
                      Weak
                    </label>
                    <div className="md:col-span-5 col-span-4">
                      <Field
                        className="ml-2 mr-2 mt-2 p-2 w-3/4 border rounded-md h-8 border-gray-400"
                        id="newTech"
                        name="newTech"
                        placeholder="Tech you want to learn"
                      />
                      <br />
                      <div className="ml-2 text-sm text-red-700">
                        <ErrorMessage name="newTech" />
                      </div>
                    </div>
                  </div>
                </div>

                <section>
                  <button
                    className="mx-2 bg-transparent hover:bg-gray-500  py-2 px-4 border border-black-500 hover:border-transparent rounded-full w-32"
                    type="submit"
                  >
                    Submit
                  </button>
                  {redirectUserToLandingPage && <Navigate to="/home" />}
                </section>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </React.Fragment>
  );
};
