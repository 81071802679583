import React from "react";
import { Header } from "./Header";

export const Privacy = () => {
  return (
    <React.Fragment>
      <Header/>
      <div className="md:w-1/2 m-auto text-[#374151]">
        <h3 className="text-2xl font-bold my-5">Privacy</h3>
        <p className="text-justify text-lg">
          Thank you for using ProjectCompanion("the Website"). This Privacy Policy
          outlines how we collect,use,disclose,and protect your personal
          information when you interact with our Website. By accessing or using
          ProjectCompanion,you acknowledge and accept the practices described in this
          Privacy Policy.
        </p>

        <h3 className="text-2xl font-bold my-5">1. Information We Collect</h3>
        <h4 className="text-xl font-bold my-5">1.1 Information You Provide:</h4>
        <p className="text-justify text-lg">
          When you register for an account,post questions,provide
          answers,comment,or engage in other activities on the Website,we may
          collect personal information from you,including but not limited
          to:-Your name-Email address-User-generated
          content(questions,answers,comments,links,etc.)-Profile picture-Other
          information you choose to provide
        </p>
        <h4 className="text-xl font-bold my-5">
          {" "}
          1.2 Automatically Collected Information:
        </h4>
        <p className="text-justify text-lg">
          We may automatically collect certain information about your usage of
          the Website,including:-IP address-Browser type and version-Device
          information-Usage patterns and preferences-Referring/exit
          pages-Operating system-Date and time stamps
        </p>

        <h3 className="text-2xl font-bold my-5">2. Use of Information</h3>
        <h4 className="text-xl font-bold my-5">
          2.1 Personalization and User Experience:{" "}
        </h4>
        <p className="text-justify text-lg">
          We use the information collected to personalize your experience on
          ProjectCompanion,improve our services,and provide relevant content and
          recommendations based on your interests and preferences.
        </p>
        <h4 className="text-xl font-bold my-5"> 2.2 Communication:</h4>
        <p className="text-justify text-lg">
          We may use your email address to send you notifications,updates,and
          important information regarding your account,the Website,or changes to
          our Privacy Policy or Terms of Service.
        </p>

        <h4 className="text-xl font-bold my-5">2.3 Aggregated Data:</h4>
        <p className="text-justify text-lg">
          We may aggregate and anonymize the collected information to generate
          statistical and analytical insights,which may be used for
          research,marketing,or other purposes.
        </p>

        <h4 className="text-xl font-bold my-5">
          2.4 Legal Compliance and Protection:
        </h4>
        <p className="text-justify text-lg">
          We may use and disclose your information as required by
          law,regulations,or legal processes. We may also use it to protect our
          rights,safety,and the rights and safety of others.
        </p>

        <h3 className="text-2xl font-bold my-5">3. Sharing of Information</h3>
        <h4 className="text-xl font-bold my-5">3.1 Service Providers:</h4>
        <p className="text-justify text-lg">
          We may share your personal information with trusted third-party
          service providers who assist us in operating,analyzing,and improving
          the Website. These service providers are obligated to handle your
          information securely and only for the purposes specified by us.
        </p>
        <h4 className="text-xl font-bold my-5"> 3.2 Public Content:</h4>
        <p className="text-justify text-lg">
          Any user-generated content you post on ProjectCompanion,such as
          questions,answers,or comments,may be visible to other users and the
          public.
        </p>

        <h4 className="text-xl font-bold my-5">3.3 Legal Requests:</h4>
        <p className="text-justify text-lg">
          We may disclose your personal information if required to do so by law
          or in response to valid requests from government authorities,including
          law enforcement agencies.
        </p>

        <h4 className="text-xl font-bold my-5">3.4 Business Transfers:</h4>
        <p className="text-justify text-lg">
          In the event of a merger,acquisition,or sale of all or a portion of
          our assets,your personal information may be transferred as part of the
          transaction. We will notify you before your information becomes
          subject to a different privacy policy.
        </p>

        <h3 className="text-2xl font-bold my-5">4. Data Security</h3>

        <p className="text-justify text-lg">
          We take reasonable measures to protect your personal information from
          unauthorized access,alteration,disclosure,or destruction.
          However,please note that no method of transmission over the Internet
          or electronic storage is 100% secure. Therefore,while we strive to use
          commercially acceptable means to protect your information,we cannot
          guarantee absolute security.
        </p>

        <h3 className="text-2xl font-bold my-5">5. Your Rights and Choices</h3>
        <h4 className="text-xl font-bold my-5">5.1 Access and Update:</h4>
        <p className="text-justify text-lg">
          You have the right to access and update the personal information you
          provide to us. You can review and modify your account information by
          logging into your ProjectCompanion account.
        </p>
        <h4 className="text-xl font-bold my-5"> 5.2 Communications:</h4>
        <p className="text-justify text-lg">
          You can manage your communication preferences by adjusting your
          account settings or unsubscribing from our emails. However,we may
          still send you important administrative messages regarding your
          account or the Website.
        </p>

        <h4 className="text-xl font-bold my-5">
          5.3 Cookies and Tracking Technologies:
        </h4>
        <p className="text-justify text-lg">
          Most web browsers are set to accept cookies by default. You can modify
          your browser settings to reject cookies or notify you when they are
          being used. However,disabling cookies may limit your access to certain
        </p>
      </div>
    </React.Fragment>
  );
};
