import React from "react";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <React.Fragment>
      <div className="mx-5 mt-20 mb-5 md:w-1/5 md:mx-auto text-center text-[#374151]">
        <hr />

        <h3 className="my-10">ProjectCompanion, Copyright 2024</h3>

        <Link className="mr-4" to="/terms">Terms & Conditions</Link>
        <Link className="ml-4" to="/privacy">Privacy</Link>
      </div>
    </React.Fragment>
  );
};
