import React from "react";
import { Header } from "./Header";

export const Terms = () => {
  return (
    <React.Fragment>
      <Header />
      <div className="md:w-1/2 m-auto text-[#374151]">
        <h3 className="text-2xl font-bold my-5">Terms and Conditions</h3>
        <p className="text-justify text-lg">
          Please read these Terms and Conditions("Terms")carefully before using
          ProjectCompanion("the Website"). These Terms govern your access to and use of
          the Website,including any content,features,or services provided by
          ProjectCompanion. By accessing or using the Website,you agree to be bound by
          these Terms. If you do not agree with any part of these Terms,you may
          not access or use the Website.
        </p>

        <h3 className="text-2xl font-bold my-5">1. Use of the Website</h3>
        <h4 className="text-xl font-bold my-5">1.1 Eligibility:</h4>
        <p className="text-justify text-lg">
          You must be at least 13 years old to use the Website. By accessing or
          using the Website,you represent and warrant that you are 13 years of
          age or older.
        </p>
        <h4 className="text-xl font-bold my-5"> 1.2 Account Registration:</h4>
        <p className="text-justify text-lg">
          To access certain features and services on ProjectCompanion,you may be required
          to create an account. You are responsible for providing accurate and
          complete information during the registration process. You must
          maintain the confidentiality of your account credentials and are
          solely responsible for all activities that occur under your account.
        </p>

        <h4 className="text-xl font-bold my-5">1.3 User Conduct:</h4>
        <p className="text-justify text-lg">
          You agree to use ProjectCompanion in compliance with applicable laws and
          regulations. When using the Website,you must not:-Post or transmit any
          content that is unlawful,harmful,defamatory,obscene,or infringing on
          intellectual property rights.-Engage in any activity that disrupts or
          interferes with the functioning of the Website or the experience of
          other users.-Attempt to gain unauthorized access to any part of the
          Website or interfere with its security measures.-Use the Website for
          any commercial or promotional purposes without our prior written
          consent.
        </p>

        <h3 className="text-2xl font-bold my-5">2. Intellectual Property</h3>

        <h4 className="text-xl font-bold my-5">2.1 Ownership</h4>
        <p className="text-justify text-lg">
          All intellectual property rights,including copyrights,trademarks,and
          any other proprietary rights,in and to the Website and its content are
          owned by ProjectCompanion or its licensors. You acknowledge that you have no
          right,title,or interest in such intellectual property rights.
        </p>

        <h4 className="text-xl font-bold my-5">2.2 User-Generated Content:</h4>
        <p className="text-justify text-lg">
          By posting or submitting any content on ProjectCompanion,you grant us a
          non-exclusive,royalty-free,worldwide,perpetual,and irrevocable license
          to use,modify,reproduce,distribute,and display the content for the
          purpose of operating and promoting the Website.
        </p>

        <h3 className="text-2xl font-bold my-5">3. Third-Party Content and Links</h3>

        <h4 className="text-xl font-bold my-5">3.1 External Websites:</h4>
        <p className="text-justify text-lg">
          ProjectCompanion may contain links to third-party websites or resources that are
          not owned or controlled by us. We are not responsible for the
          content,accuracy,or practices of these external websites. Your
          interactions with third-party websites are solely between you and the
          respective third parties.
        </p>

        <h4 className="text-xl font-bold my-5">3.2 User-Generated Content:</h4>
        <p className="text-justify text-lg">
          ProjectCompanion may contain user-generated content that is created and provided
          by other users of the Website. We do not endorse,guarantee,or assume
          any responsibility for the accuracy,completeness,or reliability of
          such user-generated content. You acknowledge that any reliance on
          user-generated content is at your own risk.
        </p>

        <h3 className="text-2xl font-bold my-5">4. Disclaimer of Warranties</h3>
        <p className="text-justify text-lg">
          ProjectCompanion is provided on an "as-is" and "as available" basis. We make no
          representations or warranties of any kind,whether express,implied,or
          statutory,regarding the operation or availability of the Website,its
          content,or any information or materials accessed through it.
        </p>

        <h3 className="text-2xl font-bold my-5">5. Limitation of Liability</h3>
        <p className="text-justify text-lg">
          o the fullest extent permitted by applicable law,ProjectCompanion and its
          officers,directors,employees,or agents shall not be liable for any
          indirect,incidental,special,consequential,or punitive damages arising
          out of or in connection with your use of the Website or these Terms.
        </p>

        <h3 className="text-2xl font-bold my-5">6. Modification of Terms</h3>
        <p className="text-justify text-lg">
          We reserve the right to modify or update these Terms at any time
          without prior notice. By continuing to use the Website after any
          modifications or updates,you agree to be bound by the revised Terms.
        </p>

        <h3 className="text-2xl font-bold my-5">7. Termination</h3>
        <p className="text-justify text-lg">
          We may,at our discretion,suspend or terminate your access to the
          Website,delete your account,or remove any content posted by
          you,without prior notice or liability,for any reason,including if we
          believe that you have violated these Terms.
        </p>

        <h3 className="text-2xl font-bold my-5">8. Governing Law</h3>
        <p className="text-justify text-lg">
          These Terms shall be governed by and construed in accordance with the
          laws of India. Any legal action or proceeding arising out of or
          related to these Terms or your use of the Website shall be brought
          exclusively in the courts of India.
        </p>

        <h3 className="text-2xl font-bold my-5">9. Cancellation and Refund Policy</h3>
        <p className="text-justify text-lg">
          Every event will have its own pricing and any amount paid for the
          event is non-refundable. Before making any payment,you will be
          prompted about the same,and only once you acknowledge that you have
          read the cancellation and refund policy then you can proceed with the
          payment. In case of disputes,please drop an email to us at projectcompanion7@gmail.com and
          we will take a look at them on a per-case basis.
        </p>

        <h3 className="text-2xl font-bold my-5">10. Contact Us</h3>
        <p className="text-justify text-lg">
          If you have any questions or concerns about these Terms,please contact
          us at projectcompanion7@gmail.com.
        </p>

        <h3 className="text-2xl font-bold my-5">Address:</h3>
        <p className="text-justify text-lg">
          ProjectCompanion Inc <br /> 
          Nagpur, Maharshtra, India
          <br />
          <br /> By using ProjectCompanion ,you acknowledge that you have
          read,understood,and agreed to these Terms and Conditions.
        </p>
      </div>
    </React.Fragment>
  );
};
