import React, { useState } from "react";
import { CheckboxFilter } from "../CheckboxFilter/CheckboxFilter";

export const FilterProjectList = ({ listOfProjects, setListOfProjects }) => {

    const [showFilters, setShowFilters] = useState(false);

    const selectedFilter = {
        type: [],
        frontendTech: [],
        backendTech: [],
    };

  const getProjectsByFilter = async () => {
    const response = await fetch(`${process.env.API_HOST}/getProjects/filter`, {
      method: "POST",
      mode: "cors",
      cache: "default",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      referrerPolicy: "same-origin",
      body: JSON.stringify(selectedFilter),
    });

    const data = await response.json();
    console.log(data.data);
    setListOfProjects(data.data);
  };

  const onSelectingCheckbox = (event) => {
    let currentFilterId = event.target.id;
    // currentFilterId = currentFilterId.split('-')[0];
    const filterValue = event.target.value;

    if (currentFilterId.includes("type")) {
      if (!event.target.checked) {
        selectedFilter.type = selectedFilter.type.filter(
          (filter) => filter !== filterValue
        );
      } else {
        selectedFilter.type.push(filterValue);
      }
    } else if (currentFilterId.includes("frontend")) {
      if (!event.target.checked) {
        selectedFilter.frontendTech = selectedFilter.frontendTech.filter(
          (filter) => filter !== filterValue
        );
      } else {
        selectedFilter.frontendTech.push(filterValue);
      }
    } else if (currentFilterId.includes("backend")) {
      if (!event.target.checked) {
        selectedFilter.backendTech = selectedFilter.backendTech.filter(
          (filter) => filter !== filterValue
        );
      } else {
        selectedFilter.backendTech.push(filterValue);
      }
    }
  };

    return (
        <React.Fragment>
            <div className="p-4 bg-aqua-400 border border-gray-400 border-solid rounded-md">
                <div className="mb-2 font-semibold cursor-pointer" onClick={()=>{setShowFilters(true)}}>
                    Show Filters
                </div>

                {
                    showFilters &&
                    <div>
                        <div className="my-2 md:flex p-2">
                            <section id="type-filters" className="mr-4">
                                <span className="font-medium">Type</span>
                                <section className="mx-4">
                                    <CheckboxFilter id="type-filters-checkbox0" filterTitle="Fullstack" filterValue="fullstack" onChange={onSelectingCheckbox}/><br/>
                                    <CheckboxFilter id="type-filters-checkbox1" filterTitle="Frontend" filterValue="frontend" onChange={onSelectingCheckbox} /><br/>
                                    <CheckboxFilter id="type-filters-checkbox2" filterTitle="Backend" filterValue="backend" onChange={onSelectingCheckbox} />
                                </section>
                            </section>

                            <section id="frontend-filters" className="mr-4">
                                <span className="font-medium">Frontend</span>
                                <section className="mx-4">
                                    <CheckboxFilter id="frontend-filters-checkbox0" filterTitle="Angular" filterValue="angular" onChange={onSelectingCheckbox} /><br/>
                                    <CheckboxFilter id="frontend-filters-checkbox1" filterTitle="React" filterValue="react" onChange={onSelectingCheckbox} /><br/>
                                    <CheckboxFilter id="frontend-filters-checkbox2" filterTitle="Vue" filterValue="vue" onChange={onSelectingCheckbox} />
                                </section>
                            </section>

                            <section id="backend-filters" className="mr-4">
                                <span className="font-medium">Backend</span>
                                <section className="mx-4">
                                    <CheckboxFilter id="backend-filters-checkbox0" filterTitle="Django" filterValue="django" onChange={onSelectingCheckbox} /><br/>
                                    <CheckboxFilter id="backend-filters-checkbox1" filterTitle="Java" filterValue="java" onChange={onSelectingCheckbox} /><br/>
                                    <CheckboxFilter id="backend-filters-checkbox2" filterTitle="NodeJS" filterValue="nodejs" onChange={onSelectingCheckbox} />
                                </section>
                            </section>
                        </div>
                        <section className="flex p-2">
                            <button className="mr-2 hover:underline hover:font-medium" onClick={getProjectsByFilter}>Apply</button>
                            <button className="hover:underline hover:font-medium" onClick={()=>{setShowFilters(false)}}>Close</button>
                        </section>
                    </div>
                }
            </div>
        </React.Fragment>
    )
}
