import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Link, Navigate } from "react-router-dom";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import { useAuthContext } from "../../hooks/useAuthContext";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Loading } from "../Loading/Loading";

export const HandleLogin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [verifyEmailMessage, setVerifyEmailMessage] = useState(false);
  const [redirectUserToLandingPage, setRedirectUserToLandingPage] =
    useState(false);

  const navigate = useNavigate();

  const { dispatch } = useAuthContext();

  const handleLogin = async (loginInputs) => {
    try {
      setIsLoading(true);
      const response = await fetch(`${process.env.API_HOST}/login`, {
        method: "POST",
        mode: "cors",
        cache: "default",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        redirect: "follow",
        referrerPolicy: "same-origin",
        body: JSON.stringify(loginInputs),
      });
      const result = await response.json();

      if (!response.ok) {
        if (result.message.includes("Username or password may be wrong")) {
          // password or email entered is incorrect
          setErrorMessage(
            "Username or password may be wrong! Please check and try again."
          );
        } else if (result.message.includes("Email is not Verified")) {
          setVerifyEmailMessage(true);
          setErrorMessage(
            "Email is not Verfied. Click on below link to verify email"
          );
        } else {
          setErrorMessage(
            "Unable to process request at this time. Please try again"
          );
        }

        setShowErrorMessage(true);
        setTimeout(() => {
          setShowErrorMessage(false);
        }, 30000);
        setIsLoading(false);
      }

      if (response.ok) {
        // login successfull redirect to user landing page
        dispatch({ type: "LOGIN", payload: result.user });

        setRedirectUserToLandingPage(true);
        setTimeout(() => {
          setRedirectUserToLandingPage(false);
        }, 5000);
      }
    } catch (error) {
      console.error("Error logging in: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const [loginSuccess, setLoginSuccess] = useState(false);

  const logOut = () => {
    googleLogout();
  };

  const handleGoogleLogin = async (token) => {
    try {
      setIsLoading(true);
      const response = await fetch(`${process.env.API_HOST}/googleLogin`, {
        method: "POST",
        mode: "cors",
        cache: "default",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        redirect: "follow",
        referrerPolicy: "same-origin",
      });
      console.log(response.ok);
      if (response.ok) {
        console.log("Success");
        setLoginSuccess(true);
      }
    } catch (error) {
      console.error("Error fetching details from GoogleAPI: ", error);
    } finally {
    }
  };

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        setIsLoading(true);
        handleGoogleLogin(tokenResponse.access_token);
        const userInfo = await fetch(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${tokenResponse.access_token}`,
            },
          }
        );
        const data = await userInfo.json();
        console.log(data);

        console.log(tokenResponse);
        console.log(data.picture);
        sessionStorage.setItem("profilePhoto", data.picture);
        const user = {
          firstName: data.given_name,
          lastName: data.family_name,
          email: data.email,
        };
        dispatch({ type: "LOGIN", payload: user });
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const signInSchema = Yup.object().shape({
    emailAddress: Yup.string().email().required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be 8 characters long")
      .required("Please enter Password"),
  });

  const verifyEmail = async () => {
    try {
      const response = await fetch(`${process.env.API_HOST}/resendOtp`, {
        method: "POST",
        mode: "cors",
        cache: "default",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: "",
        }),
        redirect: "follow",
        referrerPolicy: "same-origin",
      });

      const result = await response.json();

      if (!response.ok) {
        setMessage("Unable to process request. Please try again.");
      }

      if (response.ok) {
        if (result.message === "already verified") {
          navigate("/");
        }

        setMessage("OTP has been sent to your email id.");
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  return (
    <React.Fragment>
      <div className="w-[380px] mx-auto my-3 px-3">
        {/* Logo will be inserted here */}
        <section className="text-xl text-center p-4">
          Sign in to Project Companion
        </section>
        <div className="bg-[#f6f8fa] border border-solid border-black rounded-xl">
          <Formik
            initialValues={{
              emailAddress: "",
              password: "",
            }}
            validationSchema={signInSchema}
            onSubmit={(loginInput, { setSubmitting }) => {
              // alert(JSON.stringify( formInput));
              setIsLoading(true);
              setSubmitting(false);
              handleLogin(loginInput);
            }}
          >
            <Form className="w-full">
              <section className="mx-2 p-2">
                {showErrorMessage && errorMessage}
              </section>

              <section className="mx-2 my-2 p-2">
                <label className="text-lg mb-2" htmlFor="emailAddress">
                  Email address
                </label>
                <br />
                <Field
                  type="text"
                  className="w-full px-2 h-8 border border-gray-400 border-solid rounded-md"
                  id="emailAddress"
                  name="emailAddress"
                  placeholder="Enter your email address"
                ></Field>
                <div className="md:mx-4 text-sm text-red-700">
                  <ErrorMessage className="" name="emailAddress" />
                </div>
              </section>

              <section className="mx-2 my-2 p-2">
                <label className="text-lg" htmlFor="password">
                  Password
                </label>
                <br />
                <Field
                  type="password"
                  className="w-full px-2 h-8 border border-gray-400 border-solid rounded-md"
                  id="password"
                  name="password"
                  placeholder="Enter your password"
                ></Field>
                <div className="md:mx-4 text-sm text-red-700">
                  <ErrorMessage className="" name="password" />
                </div>
              </section>
              {showErrorMessage && errorMessage && verifyEmailMessage && (
                <section className="mx-2 my-2 p-2">
                  <button
                    onClick={() => {
                      verifyEmail();
                      navigate("/otpVerification");
                    }}
                    className="w-full h-8 text-center hover:border-transparent text-blue-800"
                  >
                    Please Verify Your Email
                  </button>
                </section>
              )}
              <section className="mx-2 my-2 p-2">
                <button
                  type="submit"
                  disabled={isLoading}
                  className="w-full h-8 text-center bg-green-500 border border-black border-solid hover:border-transparent rounded-md"
                >
                  {isLoading ? "Submitting..." : "Submit"}
                </button>
                {redirectUserToLandingPage && <Navigate to="/home" />}
              </section>
            </Form>
          </Formik>
        </div>

        <div className="my-2 p-2 text-center border border-solid border-black rounded-md">
          New to ProjectCompanion?{" "}
          <Link className="text-blue-600" to="/register">
            {" "}
            Create an account{" "}
          </Link>
        </div>

        <button
          onClick={() => login()}
          className="w-full flex items-center justify-center my-2 p-2 text-center border border-solid border-black rounded-md"
        >
          <img
            className="mx-2 h-8"
            src={require("./assets/google_icon.png")}
            alt="Sign In with Google"
          />
          <div className="mx-2">
            {loginSuccess ? <Navigate to="/home" replace={true} /> : null}
            {isLoading ? "Logging In..." : "Sign In with Google"}
          </div>
        </button>
      </div>
    </React.Fragment>
  );
};
