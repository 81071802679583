import { Field, Form, Formik, ErrorMessage } from "formik";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";
import { Link, Navigate } from "react-router-dom";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import { Cookies, CookiesProvider, useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

export const HandleRegister = () => {
  const [errorEmailExists, setErrorEmailExists] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [type, setType] = useState("password");

  const { dispatch } = useAuthContext();

  const navigate = useNavigate();

  async function handleRegister(registrationInputs) {
    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.API_HOST}/register`, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        redirect: "follow",
        referrerPolicy: "same-origin",
        body: JSON.stringify(registrationInputs),
      });

      const result = await response.json();

      if (!response.ok) {
        if (result.message.includes("duplicate")) {
          setError("Email already exists");
        } else if (result.message.includes("Invalid username or password")) {
          setError("Invalid emailaddress or password");
        } else {
          setError("Unable to process request. Please try again.");
        }
        setErrorEmailExists(true);
        setTimeout(() => {
          setErrorEmailExists(false);
        }, 3000);
        setIsLoading(false);
      }

      if (response.ok) {
        dispatch({ type: "LOGIN", payload: result.user });
        navigate("/otpVerification");
      }
    } catch (error) {
      console.log("Error: ", error);
    } finally {
      setIsLoading(false);
    }
  }

  //Handle Google Login
  const handleGoogleLogin = async (token) => {
    try {
      setIsLoading(true);
      const response = await fetch(`${process.env.API_HOST}/googleLogin`, {
        method: "POST",
        mode: "cors",
        cache: "default",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        redirect: "follow",
        referrerPolicy: "same-origin",
      });
      console.log(response.ok);
      if (response.ok) {
        console.log("Success");
        setLoginSuccess(true);
      }
    } catch (error) {
      console.error("Error logging in with Google API: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      handleGoogleLogin(tokenResponse.access_token);
      const userInfo = await fetch(
        `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${tokenResponse.access_token}`,
        {
          headers: {
            Authorization: `Bearer ${tokenResponse.access_token}`,
            Accept: "application/json",
          },
        }
      );
      const data = await userInfo.json();

      const user = {
        firstName: data.given_name,
        lastName: data.family_name,
        email: data.email,
      };
      dispatch({ type: "LOGIN", payload: user });
    },
    isSignedIn: false,
  });

  const registerSchema = Yup.object().shape({
    firstName: Yup.string().required("Please enter your First Name"),
    lastName: Yup.string().required("Please enter your Last Name"),
    emailAddress: Yup.string().email().required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be 8 characters long")
      .required("Please enter Password"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Please re-enter Password"),
    // confirmPassword: Yup.string()
    //   .min(8, "Password must be 8 characters long")
    //   .required("Please re-enter Password"),
  });

  return (
    <React.Fragment>
      <div className="w-[380px] mx-auto my-4 px-2">
        {/* Logo will be inserted here */}
        <section className="text-xl text-center p-4">
          Register on Project Companion
        </section>

        <section className="m-2 p-2  border border-solid border-black rounded-md">
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              emailAddress: "",
              password: "",
              confirmPassword: "",
              toggle: false,
            }}
            validationSchema={registerSchema}
            onSubmit={(registrationInputs, { setSubmitting }) => {
              if (
                registrationInputs.password !==
                registrationInputs.confirmPassword
              ) {
                setError("Input password & confirm password do not match");
                setErrorEmailExists(true);
                setTimeout(() => {
                  setErrorEmailExists(false);
                }, 3000);
                return;
              }
              setSubmitting(false);
              handleRegister(registrationInputs);
            }}
          >
            <Form className="w-full">
              <section className="grid grid-cols-12">
                <section className="col-span-6 m-2">
                  <label className="" htmlFor="firstName">
                    First Name:
                  </label>
                  <br />
                  <Field
                    className="w-full px-2 h-8 border border-solid border-black rounded-md"
                    type="text"
                    id="firstName"
                    name="firstName"
                    placeholder="First Name"
                  />
                  <div className="md:mx-4 text-sm text-red-700">
                    <ErrorMessage className="" name="firstName" />
                  </div>
                </section>

                <section className="col-span-6 m-2">
                  <label className="" htmlFor="lastName">
                    Last Name:
                  </label>
                  <br />
                  <Field
                    className="w-full px-2 h-8 border border-solid border-black rounded-md"
                    type="text"
                    id="lastName"
                    name="lastName"
                    placeholder="Last Name"
                  />
                  <div className="md:mx-4 text-sm text-red-700">
                    <ErrorMessage className="" name="lastName" />
                  </div>
                </section>
              </section>

              <section className="m-2">
                <label className="" htmlFor="emailaddress">
                  Email address:
                </label>
                <Field
                  className="w-full px-2 h-8 border border-solid border-black rounded-md"
                  type="email"
                  id="emailAddress"
                  name="emailAddress"
                  placeholder="Email Address"
                />
                <div className="md:mx-4 text-sm text-red-700">
                  <ErrorMessage className="" name="emailAddress" />
                </div>
                {errorEmailExists && (
                  <section>
                    <span className="text-red-600 px-2">{error}</span>
                    Please try
                    <Link className="text-yellow-400 px-2">Log In</Link>
                  </section>
                )}
              </section>

              <section className="m-2">
                <label className="" htmlFor="password">
                  Password:
                </label>
                <Field
                  className="w-full px-2 h-8 border border-solid border-black rounded-md"
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Password"
                />
                <div className="md:mx-4 text-sm text-red-700">
                  <ErrorMessage className="" name="password" />
                </div>
              </section>

              <section className="m-2">
                <label className="" htmlFor="emailaddress">
                  Confirm Passowrd:
                </label>
                <Field
                  className="w-full px-2 h-8 border border-solid border-black rounded-md"
                  type={type}
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder="Confirm password"
                />
                <div className="md:mx-4 text-sm text-red-700">
                  <ErrorMessage className="" name="confirmPassword" />
                </div>
                <label className="text-sm">
                  <Field
                    onClick={(value) => {
                      toggle = !value;
                      if (type == "password") {
                        setType("text");
                      } else {
                        setType("password");
                      }
                    }}
                    className="m-2"
                    type="checkbox"
                    name="toggle"
                  />
                  Show Password
                </label>
              </section>

              <section className="m-2">
                <button
                  type="submit"
                  disabled={isLoading}
                  className="w-full h-8 border border-solid border-black rounded-md bg-stone-700 text-white"
                >
                  {isLoading ? "Adding you in..." : "Register"}
                </button>
              </section>

              <section className="m-2">
                <div className="inline-flex items-center justify-center w-full">
                  <hr className="w-64 h-px my-8 bg-gray-200 border-0" />
                  <span className="absolute px-3 font-medium text-gray-900 -translate-x-1/2 bg-white left-1/2">
                    or
                  </span>
                </div>
                <button
                  onClick={() => {
                    login();
                  }}
                  type="button"
                  className="flex items-center justify-center w-full h-8 border border-solid border-black rounded-md bg-white text-black"
                >
                  <img
                    className="mx-2 h-6"
                    src={require("./assets/google_icon.png")}
                    alt="Google Sign In"
                  />
                  <div className="mx-2">
                    {loginSuccess ? (
                      <Navigate to="/home" replace={true} />
                    ) : null}
                    {isLoading ? "Logging you in..." : "Continue with Google"}
                  </div>
                </button>
              </section>
            </Form>
          </Formik>
        </section>

        <section className="m-2 p-2 text-center border border-solid border-black rounded-md">
          Already a user?{" "}
          <Link className="text-blue-600" to="/login">
            {" "}
            Sign In{" "}
          </Link>
        </section>
      </div>
    </React.Fragment>
  );
};
