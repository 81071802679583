import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { Loading } from "../../Loading/Loading";

export const ProposalProfile = () => {
  const [profileProjects, setProfileProjects] = useState();
  const [userProfile, setUserProfile] = useState();
  const [loading, setLoading] = useState(true);
  const [userProfileLoading, setUserProfileLoading] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();

  const { user } = useAuthContext();

  const getUserProfile = async () => {
    try {
      const response = await fetch(`${process.env.API_HOST}/getProfileforUser/${location.state.emailAddress}`, {
        method: "GET",
        credentials: "include",
      });
      const data = await response.json();
      setUserProfile(data);
    } catch (error) {
      console.error("Error fetching user profile for sender: ", error);
    } finally {
      setUserProfileLoading(false);
    }
  };

  const getUserProfileProjects = async () => {
    try {
      const response = await fetch(
        `${process.env.API_HOST}/getProfileProjectsforUser/${location.state.emailAddress}`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      const data = await response.json();
      setProfileProjects(data);
    } catch (error) {
      console.error(
        "Error fetching profile projects for proposal sender: ",
        error
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if(!user){
        navigate('/login')
    }
    getUserProfileProjects();
    getUserProfile();
  }, []);

  if (loading || userProfileLoading) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <div className="text-3xl my-4 mx-8">Sender's Profle</div>
      <hr className="mx-12 my-1" />
      <div className="mx-12 my-8">
        <p className="mt-1 ml-1 p-2 text-xl">
          {" "}
          <span className="font-semibold">NAME: </span>
          {userProfile.firstname + " " + userProfile.lastname}
        </p>
        <p className="mt-1 ml-1 p-2 text-xl">
          {" "}
          <span className="font-semibold">GitHub: </span>
          {userProfile.github}
        </p>
        <h2 className="ml-1 p-2 font-semibold text-xl">PROJECTS:</h2>
        {profileProjects?.map((project, index) => {
          return (
            <>
              <div className="mx-4">
                <p className="text-xl font-medium">
                  {index + 1}. <span className="underline">{project.name}</span>
                </p>
                <p className="mx-4 my-2">
                  <span className="text-lg font-medium">DESCRIPTION: </span>
                  {project.description}
                </p>
                <p className="mx-4 my-2">
                  <span className="text-lg font-medium">PROJECT LINK: </span>
                  {project.projectlink}
                </p>
                <p className="mx-4 my-2">
                  <span className="text-lg font-medium">TECH USED: </span>
                  {project.tech}
                </p>
              </div>
            </>
          );
        })}
         <p className="mt-1 ml-1 p-2 text-xl">
          {" "}
          <span className="font-semibold">EXPERIENCE: </span>
          {userProfile.experience}
        </p>
        <p className="mt-1 ml-1 p-2 text-xl">
          {" "}
          <span className="font-semibold">STRONG TECH: </span>
          {userProfile.oldtech}
        </p>
        <p className="mt-1 ml-1 p-2 text-xl">
          {" "}
          <span className="font-semibold">WEAK TECH: </span>
          {userProfile.newtech}
        </p>
      </div>
    </React.Fragment>
  );
};
