import React, { useEffect, useState, useRef } from "react";
import "./header.css";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";

export const Header = () => {
  const { user } = useAuthContext();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const signOut = async () => {
    try {
      const response = await fetch(`${process.env.API_HOST}/signOut`, {
        method: "GET",
        credentials: "include",
      });
      const result = await response.json();
      if (response.ok) {
        if (result.message === "User Logged Out Successfully") {
          navigate("/");
          navigate(0);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        // Click outside the dropdown, close it
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("click", handleOutsideClick);
    }

    // Remove the event listener when the component unmounts or the dropdown is closed
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isOpen, user]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <React.Fragment>
      <div className="flex shadow-md justify-between pt-2 pl-2 pb-2 pr-2 h-16 items-center">
        <div
          className="text-xl md:text-2xl md:pl-4 font-semibold cursor-pointer"
          onClick={() => {
            navigate("/");
          }}
        >
          Project Companion
        </div>
        {!user && (
          <div className="login-signup-container">
            <Link className="pr-3 text-blue-600" to="/login">
              Log In
            </Link>
            or
            <Link className="pl-3 text-yellow-400" to="/register">
              Sign Up
            </Link>
          </div>
        )}

        {user && (
          <div ref={dropdownRef}>
            <div className="flex items-center">
              {user.firstName + " " + user.lastName}
              <button
                onClick={() => {
                  toggleDropdown();
                }}
                className=""
              >
                <img
                  className="mx-2 w-12 h-12 p-1 rounded-full ring-2 ring-gray-300 dark:ring-gray-500"
                  src={sessionStorage.getItem("profilePhoto")}
                  alt="Bordered avatar"
                />
              </button>
            </div>

            {isOpen && (
              <div className="z-50 absolute bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                <ul
                  className="py-2 text-sm text-gray-700 dark:text-gray-200"
                  aria-labelledby="dropdownDefaultButton"
                >
                  <li>
                    <a
                      href="#"
                      className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      About Us
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        signOut();
                      }}
                      href="#"
                      className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      Sign out
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
