import React, { useEffect, useState } from "react";
import { OTPInput } from "./otpInput";
import { useNavigate } from "react-router-dom";

export const OtpVerification = () => {
  const navigate = useNavigate();

  //state to store all input boxes
  const [inputValues, setInputValues] = useState({
    input1: "",
    input2: "",
    input3: "",
    input4: "",
    input5: "",
    input6: "",
    // Add more input values here
  });
  const [otp, setOtp] = useState("");
  const [flag, setFlag] = useState(true);
  const [min, setMin] = useState(2);
  const [sec, setSec] = useState(0);
  const [message, setMessage] = useState("");
  const [verified, setVerified] = useState(true);

  const timer = () => {
    const current_time = new Date();
    current_time.setMinutes(current_time.getMinutes() + 1);
    const req_time = current_time;

    const interval = setInterval(() => {
      const time = Date.parse(req_time) - Date.now();
      //   console.log(time);
      //   console.log(flag);
      setMin(Math.floor((time % (1000 * 60 * 60)) / (1000 * 60)));
      setSec(Math.floor((time % (1000 * 60)) / 1000));

      if (time < 0) {
        clearInterval(interval);
        setMin(0);
        setSec(0);
        setFlag(false);
      }
    }, 1000);
  };

  useEffect(() => {
    timer();
  }, []);

  //this function updates the value of the state inputValues
  const handleInputChange = (inputId, value) => {
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [inputId]: value,
    }));
    setOtp(otp + value);
  };
  //this function processes form submission
  async function handleSubmit() {
    // ... Your submit logic here
    console.log(inputValues);
    try {
      const response = await fetch(`${process.env.API_HOST}/otpVerify`, {
        method: "POST",
        mode: "cors",
        cache: "default",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "same-origin",
        body: JSON.stringify({
          otp:
            inputValues.input1 +
            inputValues.input2 +
            inputValues.input3 +
            inputValues.input4 +
            inputValues.input5 +
            inputValues.input6,
        }),
      });

      const result = await response.json();

      if (!response.ok) {
        if (result.message.includes("OTP expired")) {
          setMessage("OTP has been expired");
        } else if (result.message.includes("Incorrect OTP")) {
          setError("Please enter the correct OTP");
        } else {
          setError("Unable to process request. Please try again.");
        }
      }

      if (response.ok) {
        setMessage("You have been vaerified");
        navigate("/");
        setVerified(true);
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  }

  const resendOTP = async () => {
    setFlag(true);
    timer();

    try {
      const response = await fetch(`${process.env.API_HOST}/resendOtp`, {
        method: "POST",
        mode: "cors",
        cache: "default",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "same-origin",
      });

      const result = await response.json();

      if (!response.ok) {
        setMessage("Unable to process request. Please try again.");
      }

      if (response.ok) {
        if (result.message === "already verified") {
          navigate("/");
        }

        setMessage("OTP has been sent to your email id.");
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  //return child component
  return (
    <div className="m-auto w-[54%] py-20 px-10">
      <div className="p-5 text-center">
        <h3>Otp has been sent to your eamil id for verification</h3>
      </div>
      <div id="OTPInputGroup" className="p-5 w-full" data-autosubmit="true">
        <OTPInput
          className="w-[8%] p-[1%]"
          id="input1"
          value={inputValues.input1}
          onValueChange={handleInputChange}
          previousId={null}
          nextId="input2"
        />
        <OTPInput
          className="w-[8%] p-[1%]"
          id="input2"
          value={inputValues.input2}
          onValueChange={handleInputChange}
          previousId="input1"
          nextId="input3"
        />
        <OTPInput
          className="w-[8%] p-[1%]"
          id="input3"
          value={inputValues.input3}
          onValueChange={handleInputChange}
          previousId="input2"
          nextId="input4"
        />
        <OTPInput
          className="w-[8%] p-[1%]"
          id="input4"
          value={inputValues.input4}
          onValueChange={handleInputChange}
          previousId="input3"
          nextId="input5"
        />
        <OTPInput
          className="w-[8%] p-[1%]"
          id="input5"
          value={inputValues.input5}
          onValueChange={handleInputChange}
          previousId="input4"
          nextId="input6"
        />
        <OTPInput
          className="w-[8%] p-[1%]"
          id="input6"
          value={inputValues.input6}
          onValueChange={handleInputChange}
          previousId="input5"
          nextId="input7"
        />
      </div>
      <div className="text-center">
        <p>
          <button onClick={resendOTP} disabled={flag} className="active:">
            Resend Mail
          </button>{" "}
          in <span>{"0" + min + ":"}</span>
          <span>{sec < 10 ? "0" + sec : sec}</span> seconds
        </p>
      </div>
      <div className="text-center">
      <button onClick={resendOTP} disabled={flag} className=" text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2">
            Resend
          </button>
        <button
          type="submit"
          onClick={handleSubmit}
          className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
        >
          Submit
        </button>
      </div>
    </div>
  );
};
