import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './src/App';
import './index.css'
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthContextProvider } from './src/contexts/AuthContext';

const rootElement = ReactDOM.createRoot(document.getElementById('root'));
rootElement.render(
    <AuthContextProvider>
        <GoogleOAuthProvider clientId={`${process.env.GOOGLE_OAUTH_CLIENT_ID}`}>
            <App/>
        </GoogleOAuthProvider>
    </AuthContextProvider>
)