import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { Loading } from "../../Loading/Loading";

export const SendProposal = () => {
  const { user } = useAuthContext();
  const navigate = useNavigate();

  const navigateCreateProfile = () => {
    navigate("/user/CreateProfile");
  };

  const navigateLandingPage = () => {
    navigate("/home");
  };

  const params = useParams();
  const [proposalProject, setProposalProject] = useState({});
  const [userProfile, setUserProfile] = useState({});

  const [loadingProject, setLoadingProject] = useState(true);
  const [loadingUserProfile, setLoadingUserProfile] = useState(true);

  const getProjectForProposal = async (projectId) => {
    try {
      const response = await fetch(
        `${process.env.API_HOST}/project/${parseInt(params?.projectId)}`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      const data = await response.json();
      setProposalProject(data);
    } catch (error) {
      console.error("Error fetching project to send proposal: ", error);
    } finally {
      setLoadingProject(false);
    }
  };

  const checkUserProfile = async () => {
    try {
      const response = await fetch(`${process.env.API_HOST}/getUserProfile`, {
        method: "GET",
        credentials: "include",
      });
      const data = await response.json();
      setUserProfile(data);
    } catch (error) {
      console.error("Error fetching user profile: ", error);
    } finally {
      setLoadingUserProfile(false);
    }
  };

  const handleSubmitNewProposal = async (projectFieldValues) => {
    try {
      const response = await fetch(`${process.env.API_HOST}/addNewProposal`, {
        method: "POST",
        mode: "cors",
        cache: "default",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "same-origin",
        body: JSON.stringify({
          proposedApproach: projectFieldValues.proposedApproach,
          proposedDuration: projectFieldValues.proposedDuration,
          proposedTechStack: projectFieldValues.proposedTechStack,
          projectId: params.projectId,
        }),
      });
      const result = await response.json();
    } catch (error) {
      console.error("Error submitting you proposal: ", error);
    } finally {
    }
  };

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
    checkUserProfile();
    getProjectForProposal();
  }, []);

  const validateProposalSchema = Yup.object().shape({
    proposedApproach: Yup.string()
      .min(300, "Please Propose a Approach of atleast 300 Characters")
      .required("Approach is Required"),
    proposedDuration: Yup.string()
      .min(4, "Please Enter a valid Duration. Ex: 4 Monhts")
      .required("Duration is Required"),
    proposedTechStack: Yup.string().min(4).required("Tech Stack is Required"),
  });

  return (
    <React.Fragment>
      {loadingUserProfile || loadingProject ? (
        <Loading />
      ) : (
        <>
          <div className="md:m-auto md:w-[95%] md:flex">
            <Formik
              initialValues={{
                proposedApproach: "",
                proposedDuration: "",
                proposedTechStack: "",
              }}
              validationSchema={validateProposalSchema}
              onSubmit={(projectFieldValues, { setSubmitting }) => {
                setSubmitting(false);
                userProfile.length == 0
                  ? (alert(
                      "To Send Proposal please craete your Profile. Click Ok to Continue!!"
                    ),
                    navigateCreateProfile())
                  : (handleSubmitNewProposal(projectFieldValues),
                    alert("Your Proposal is Submitted Successfully"),
                    navigateLandingPage());
              }}
            >
              <div className="m-4 md:w-2/3">
                <span className="text-2xl font-medium">
                  Send a new Proposal
                </span>
                <hr className="my-1" />
                <Form className="md:w-full mx-2 md:mx-10">
                  <div className="md:grid md:grid-cols-12 mt-5 md:mt-0">
                    <label
                      className="md:col-span-2 mx-2 md:m-2 md:p-2 text-xl"
                      htmlFor="proposedApproach"
                    >
                      Approach
                    </label>
                    <div className="md:col-span-9">
                      <Field
                        as="textarea"
                        className="md:col-span-9 w-11/12 md:w-4/5 h-32 m-2 p-2 border rounded-md border-gray-400"
                        id="proposedApproach"
                        name="proposedApproach"
                      />
                      <br />
                      <div className="mx-4 text-sm text-red-700">
                        <ErrorMessage name="proposedApproach" />
                      </div>
                    </div>
                  </div>
                  <div className="md:grid md:grid-cols-12">
                    <label
                      className="md:col-span-2 mx-2 md:m-2 md:p-2 text-xl"
                      htmlFor="proposedDuration"
                    >
                      Duration
                    </label>
                    <div className="md:col-span-9">
                      <Field
                        className="h-8 m-2 p-2 md:w-1/4 border rounded-md border-gray-400"
                        id="proposedDuration"
                        name="proposedDuration"
                        placeholder="In months ex: 3 Months"
                      />
                      <br />
                      <div className="mx-4 text-sm text-red-700">
                        <ErrorMessage name="proposedDuration" />
                      </div>
                    </div>
                  </div>
                  <div className="md:grid md:grid-cols-12">
                    <label
                      className="mx-2 md:m-2 md:p-2 md:col-span-2 text-xl"
                      htmlFor="techStack"
                    >
                      Tools
                    </label>
                    <div className="md:col-span-9">
                      <Field
                        className="m-2 p-2 md:col-span-9 w-11/12 md:w-4/5 border rounded-md h-8 border-gray-400"
                        id="proposedTechStack"
                        name="proposedTechStack"
                        placeholder="Mention tools you will be using when building project"
                      />
                      <br />
                      <div className="mx-4 text-sm text-red-700">
                        <ErrorMessage name="proposedTechStack" />
                      </div>
                    </div>
                  </div>
                  <div className="my-4 mx-2">
                    <button
                      type="submit"
                      className="bg-transparent hover:bg-gray-500 hover:text-white py-2 px-4 border border-black-500 hover:border-transparent rounded-full"
                    >
                      Send proposal
                    </button>
                  </div>
                </Form>
              </div>
            </Formik>

            <div className="md:w-1/3 mx-2 my-4 p-2 md:border-l md:border-l-black ">
              <span className="text-lg font-semibold">Project</span>
              <hr className="my-1" />
              <span className="text-xl p-2">
                {proposalProject?.projectname}
              </span>
              <p className="text-justify my-2 px-4">
                {proposalProject?.projectdescription}
              </p>
              <section className="flex px-4 font-medium">
                <span className="mr-2">
                  Level: {proposalProject?.projectlevel}
                </span>
                <span className="mx-4">
                  Tools preferred: {proposalProject?.projecttechstack}
                </span>
              </section>
              <span className="px-4">
                Duration: {proposalProject?.duration}
              </span>
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  );
};
